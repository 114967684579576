<script setup>
import aboutUs from './aboutUs.vue';
import heroBlock from './heroBlock.vue';
import headerPage from './header.vue';
import servicesBlock from './servicesBlock.vue';
import teamBlock from './teamBlock.vue';
import casesBlock from './casesBlock.vue';
import ApplicationBlock from './ApplicationBlock.vue';
import footerBlock from './footerBlock.vue';
import mediaBlock from './mediaBlock.vue';
import preloaderBlock from './preloaderBlock.vue';
</script>

<template>
  <div class="main">
    <preloaderBlock v-if="preload===true"></preloaderBlock>
    <div :class="{preload: preload===true, notPreload: preload===false}">
      <headerPage></headerPage>
      <heroBlock></heroBlock>
      <aboutUs id="about"></aboutUs> 
      <servicesBlock id="services" @servicesData="servicesLoad"></servicesBlock>
      <casesBlock id="cases" title="Кейсы" @casesData="casesLoad" :currentCase="{casesId: 'null'}"></casesBlock> 
      <teamBlock id="team" @teamData="teamLoad"></teamBlock>
      <mediaBlock>  </mediaBlock>
      
      <div class="noise"></div>
    </div>
    <div class="end" :class="{preload: preload===true, notPreload: preload===false}">
      <ApplicationBlock id="application"></ApplicationBlock>
      <footerBlock id="contacts"></footerBlock>
      <div class="noise"></div>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return{
      preload: true,
      cases: false,
      services: false,
      team: false
    }
  },
  methods:{
    casesLoad(data){
      this.cases=data
    },
    servicesLoad(data){
      this.services=data
    },
    teamLoad(data){
      this.team=data
    }
  },
  watch:{
    cases(){
      if(this.cases === true && this.services === true && this.team === true){
        this.preload=false
        
      }
    },
    services(){
      if(this.cases === true && this.services === true && this.team === true){
        this.preload=false
        
      }
    },
    team(){
      if(this.cases === true && this.services === true && this.team === true){
        this.preload=false
        
      }
    }
  }
}
</script>

<style lang="sass" scoped>
.main 
  position: relative
  .noise
    position: absolute
    z-index: 1
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: url('/src/assets/noise.png')
    mix-blend-mode: overlay
    pointer-events: none
  .preload
    display: none 
  .notPreload
    position: relative
  .end
    position: relative

@media (min-width: 1216px) and (max-width: 1900px)
  .main
    .end 
      background: var(--milkyBG)
      .noise
        display: none
  
</style>