<template>
    <div class="shellApl">
        <div class="applicationMain">
            <div class="applicationTitle titleh1">
                запись на&nbsp;аудит
            </div>
            <form class="form" @submit.prevent="submitForm">
                <input class="input sbrf" @paste.prevent type="text" placeholder="Как к вам можно обращаться*" v-model="name" @input="update" :class="{dataError: errorName===true}">
                <input class="input sbrf" @paste.prevent type="text" placeholder="Ник в Telegram" v-model="nickName" @input="update">
                <input class="input sbrf" @paste.prevent type="text" placeholder="Номер телефона для связи*" v-model="number" @input="updateNumber"  :class="{dataError: errorNumber===true}" />
                <div class="inputService">
                    <input class="input list sbrf" type="text" placeholder="Услуга, которой заинтересовались*" v-model="serviceName" @click="listOpen" @input="update" readonly>
                    <img src="../assets/icons/arrowListDown.svg" alt="" :class="{inputClose: open===false, inputOpen: open===true}" @click="listOpen">
                </div>
                

                

                <div class="list" :class="{listClose: open===false, listOpen: open===true}" :style="{'--flex-height-app': `${services.length*29}px`}">
                    <div class="service sbf" v-for="item in services" :key="item.id" @click="selectService(item.name)">
                        {{ item.name }}
                    </div>
                </div>

                <div class="checkboxBlock">

                    <img src="../assets/icons/checkbox.svg" name="checkbox" class="checkbox" 
                    @click="checkboxFunction()"
                    
                    :class="{Active: checkbox===false, notActive: checkbox===true}">

                    <img src="../assets/icons/checkboxActive.svg" name="checkbox" class="checkboxActive" 
                    @click="checkboxFunction()"
                    :class="{Active: checkbox===true, notActive: checkbox===false}">

                    <label for="checkbox" class="label sbrf" @click="checkboxFunction()">
                        Я согласен с Политикой конфиденциальности
                    </label>
                </div>
                <button class="btn sbf" :class="{btnActive: btnActive===true, btnNotActive: btnActive===false}" @click="button">Отправить</button>
                <div class="noise"></div>
                <div class="modalSent" v-if="modalSent===true">
                    <div class="modalWindow bf">
                        Спасибо за заявку, скоро мы свяжемся с вами!
                        <img src="../assets/icons/iconClose.svg" alt="" class="modalClose" @click="closeModal">
                    </div>
                </div>
                <div class="modalSent" v-if="modalError===true">
                    <div class="modalWindow bf">
                        Что-то пошло не так... Попробуйте отправить позже.
                        <img src="../assets/icons/iconClose.svg" alt="" class="modalClose" @click="closeModal">
                    </div>
                </div>
            </form>
        </div>
        <div class="noise"></div>
    </div>
</template>

<style lang="sass" scoped>
@media (min-width: 1901px) 
    .applicationMain
        overflow: hidden
        position: relative
        display: flex
        background: var(--milkyBG)
        mix-blend-mode: overlay
        padding: 159px 79px 71px 144px
        justify-content: space-between
        align-items: center
        .applicationTitle
            position: relative
            z-index: 2
            text-transform: uppercase
        .form
            z-index: 2
            padding: 72px 62px 56px 81px
            background: var(--txtDark)
            box-sizing: border-box
            position: relative
            max-width: 881px
            height: 697px
            
            .input:placeholder-shown
                background: var(--txtDark) 
                z-index: 2
                padding: 16px
                position: relative
                color: #E7E9E880
                width: 100%
                outline: none
                border: none
                margin-bottom: 40px
                border-bottom: 1px solid
                box-sizing: border-box
            .input:not(:placeholder-shown)
                background: var(--txtDark) 
                z-index: 2
                padding: 16px
                position: relative
                color: var(--milkyBG)
                width: 100%
                outline: none
                border: none
                margin-bottom: 40px
                border-bottom: 1px solid
                box-sizing: border-box
            .input:focus
                border-bottom: 1px solid var(--milkyBG)
            
            .list
                cursor: pointer
                
            .list:not(:placeholder-shown)
                text-transform: uppercase
            .inputClose
                animation: Close 300ms ease-in-out
                position: absolute
                top: 393.5px
                z-index: 20
                right: 78px
                width: 24px
                height: 24px
                transform: rotate(0deg)
            .inputOpen
                animation: Open 300ms ease-in-out
                position: absolute
                top: 393.5px
                z-index: 20
                right: 78px
                width: 24px
                height: 24px
                transform: rotate(180deg)
            .listClose
                display: none
                animation: listClose 300ms ease-in-out
                position: absolute
                width: 100%
                height: 0px
            .listOpen
                animation: listOpen 300ms ease-in-out
                position: absolute
                width: calc( 100% - 143px )
                max-width: 738px
                z-index: 25
                top: 436px
                .service
                    background: var(--txtDark)
                    border: 1px solid var(--milkyBG)
                    text-transform: uppercase
                    display: flex
                    padding: 10px
                    align-items: center
                    color: var(--milkyBG)
                .service:hover
                    animation: serviceAnim 300ms ease-in-out
                    background: var(--accentBlue)
            .checkboxBlock
                position: relative
                display: flex
                gap: 16px
                align-items: center
                .label
                    position: relative
                    color: var(--milkyBG)
                    z-index: 2
                    cursor: pointer
                .checkbox
                    width: 35px
                    height: 35px
                    position: relative
                    z-index: 2
                    background: var(--txtDark) 
                .checkboxActive
                    cursor: pointer
                    animation: checkboxActive 300ms ease-in-out
                    width: 35px
                    height: 35px
                    position: absolute
                    z-index: 2
                    background: var(--txtDark) 
                .Active
                    animation: checkboxActive 300ms ease-in-out
                    opacity: 1
                .notActive
                    animation: checkboxNotActive 300ms ease-in-out
                    opacity: 0
            .btn
                padding: 20px 40px
                margin-top: 48px
                position: relative
                z-index: 23
                background: var(--txtDark) 
                text-transform: uppercase
            .btnActive
                border: 1px solid var(--milkyBG)
                color: var(--milkyBG)
                cursor: pointer
            .btnActive:hover
                animation: serviceAnim 300ms ease-in-out
                background: var(--accentBlue)
            .btnNotActive
                color: rgba(231, 233, 232, 0.50)
                border: 1px solid rgba(231, 233, 232, 0.50)
            .dataError
                color: #D33939 !important
            .dataError:focus
                color: var(--milkyBG) !important
                border-bottom: 1px solid #D33939 !important

            .modalSent
                position: absolute
                z-index: 100
                display: flex
                top: 0
                left: 0
                height: 100%
                width: 100%
                background-color: rgba(231, 233, 232, 0.6)
                justify-content: center
                align-items: center
                padding: 217px 167px
                box-sizing: border-box
                .modalWindow
                    position: relative
                    display: flex
                    align-items: center
                    justify-content: center
                    border-radius: 20px
                    border: 1px solid var(--textBlack, #383838)
                    background: var(--backroundMilky, #E7E9E8)
                    padding: 67px 33px
                    text-align: center
                    line-height: 120%
                    color: var(--textBlack)
                    text-transform: uppercase
                    .modalClose
                        cursor: pointer
                        position: absolute
                        right: 0
                        top: 0
                        width: 27px
                        height: 27px
                        margin: 16px
                

    @keyframes checkboxActive 
        0%
            opacity: 0
        
        100%
            opacity: 1

    @keyframes checkboxNotActive 
        0%
            opacity: 1
        
        100%
            opacity: 0

    @keyframes Open 
        0%
            transform: rotate(0deg)
        
        100%
            transform: rotate(180deg)

    @keyframes Close 
        0%
            transform: rotate(180deg)
        
        100%
            transform: rotate(0deg)

    @keyframes listOpen
        0%
            height: 0px
        
        100%
            height: 204px

    @keyframes serviceAnim 
        0%
            background: var(--txtDark)
        100% 
            background: var(--accentBlue)
@media (max-width: 1215px)
    .applicationMain
        overflow: hidden
        position: relative
        display: flex
        background: var(--milkyBG)
        mix-blend-mode: overlay
        padding: 56px 16px 44px 16px
        flex-direction: column
        justify-content: space-between
        align-items: center
        gap: 31px
        
        .applicationTitle
            position: relative
            z-index: 2
        .form   
            z-index: 2
            padding: 24px 16px 29px 16px
            background: var(--txtDark)
            box-sizing: border-box
            position: relative
            width: 100%
            .input
                font-size: 14px
                font-style: normal
                font-weight: 400
                line-height: 120%
                text-transform: none
            .input:placeholder-shown
                background: var(--txtDark) 
                z-index: 2
                padding: 16px 8px
                position: relative
                color: #E7E9E880
                width: 100%
                outline: none
                border: none
                margin-bottom: 16px
                border-bottom: 1px solid
                box-sizing: border-box
            .input:not(:placeholder-shown)
                background: var(--txtDark) 
                z-index: 2
                padding: 16px 8px
                position: relative
                color: var(--milkyBG)
                width: 100%
                outline: none
                border: none
                margin-bottom: 16px
                border-bottom: 1px solid
                box-sizing: border-box
            .input:focus
                border-bottom: 1px solid var(--milkyBG)
            .inputClose
                animation: Close 300ms ease-in-out
                position: absolute
                top: 235px
                z-index: 20
                right: 24px
                width: 20px
                height: 20px
                transform: rotate(0deg)
            .inputOpen
                animation: Open 300ms ease-in-out
                position: absolute
                top: 235px
                z-index: 20
                right: 24px
                width: 20px
                height: 20px
                transform: rotate(180deg)
            .list:not(:placeholder-shown)
                text-transform: uppercase
            .listClose
                display: none
                animation: listClose 300ms ease-in-out
                position: absolute
                width: 100%
                height: 0px
                
            .listOpen
                animation: listOpen 300ms ease-in-out
                position: absolute
                width: calc( 100% - 32px )
                z-index: 25
                top: 270px
                height: var(--flex-height-app)
                .service
                    background: var(--txtDark)
                    border: 1px solid var(--milkyBG)
                    display: flex
                    padding: 10px
                    align-items: center
                    color: var(--milkyBG)
                    text-transform: uppercase
            .checkboxBlock
                padding: 10px 10px 10px 0
                position: relative
                display: flex
                gap: 16px
                align-items: center
                
                .label
                    position: relative
                    color: var(--milkyBG)
                    z-index: 2
                    text-transform: none
                    cursor: pointer
                .checkbox
                    width: 17px
                    height: 17px
                    position: relative
                    z-index: 2
                    background: var(--txtDark)
                .checkboxActive
                    width: 17px
                    height: 17px
                    position: absolute
                    z-index: 2
                    background: var(--txtDark)
                .Active
                    animation: checkboxActive 300ms ease-in-out
                    opacity: 1
                .notActive
                    animation: checkboxNotActive 300ms ease-in-out
                    opacity: 0
            .btn
                padding: 14px 40px
                margin-top: 16px
                width: 100%
                position: relative
                z-index: 23
                background: var(--txtDark)
                font-size: 14px
                font-weight: 500
                text-transform: uppercase
            .btnActive
                border: 1px solid var(--milkyBG)
                color: var(--milkyBG)
            .btnNotActive
                color: rgba(231, 233, 232, 0.50)
                border: 1px solid rgba(231, 233, 232, 0.50)
            .dataError
                color: #D33939 !important
            .dataError:focus
                color: var(--milkyBG) !important
                border-bottom: 1px solid #D33939 !important
            .modalSent
                position: absolute
                z-index: 100
                display: flex
                top: 0
                left: 0
                height: 100%
                width: 100%
                background-color: rgba(231, 233, 232, 0.6)
                justify-content: center
                align-items: center
                padding: 74px 10px 92px 10px
                box-sizing: border-box
                .modalWindow
                    position: relative
                    display: flex
                    align-items: center
                    justify-content: center
                    border-radius: 20px
                    border: 1px solid var(--textBlack, #383838)
                    background: var(--backroundMilky, #E7E9E8)
                    padding: 57px 21px
                    text-align: center
                    text-transform: uppercase
                    color: #000
                    text-align: center
                    font-size: 24px
                    .modalClose
                        position: absolute
                        right: 0
                        top: 0
                        width: 20px
                        height: 20px
                        margin: 10px

@media screen and (min-width: 1216px) and (max-width: 1900px)  
    .shellApl
        width: 100%
        display: flex
        justify-content: center
        padding: 57px 0
        background: var(--milkyBG)
        
        .noise 
            position: absolute
            left: 0
            top: 0
            width: 100% 
            height: 100%
            background: url('../assets/noise.png')
            mix-blend-mode: overlay
        .applicationMain
            max-width: 1216px
            overflow: hidden
            position: relative
            display: flex
            justify-content: space-between
            align-items: center
            .applicationTitle
                position: relative
                font-size: 64px
                line-height: 120%
                z-index: 2
                text-transform: uppercase
            .form
                z-index: 2
                padding: 32px 56px 56px 56px
                background: var(--txtDark)
                box-sizing: border-box
                position: relative
                max-width: 732px
                height: 528px
                .input:placeholder-shown
                    background: var(--txtDark)  url('../assets/noise.png')
                    font-size: 20px
                    max-height: 58px
                    z-index: 2
                    padding: 16px
                    position: relative
                    color: #E7E9E880
                    width: 100%
                    outline: none
                    border: none
                    margin-bottom: 16px
                    border-bottom: 1px solid
                    box-sizing: border-box
                .input:not(:placeholder-shown)
                    background: var(--txtDark) url('../assets/noise.png') 
                    font-size: 20px
                    max-height: 58px
                    z-index: 2
                    padding: 16px
                    position: relative
                    color: var(--milkyBG)
                    width: 100%
                    outline: none
                    border: none
                    margin-bottom: 16px
                    border-bottom: 1px solid
                    box-sizing: border-box
                .input:focus
                    border-bottom: 1px solid var(--milkyBG)

                .list
                    cursor: pointer
                .list:not(:placeholder-shown)
                    text-transform: uppercase
                .inputService
                    position: relative
                    input
                        margin-bottom: 0
                    img 
                        position: absolute
                        top: 16px
                        z-index: 20
                        right: 16px
                        width: 24px
                        height: 24px
                    .inputClose
                        animation: Close 300ms ease-in-out
                        transform: rotate(0deg)
                    .inputOpen
                        animation: Open 300ms ease-in-out
                        transform: rotate(180deg)
                .listClose
                    display: none
                    animation: listClose 300ms ease-in-out
                    position: absolute
                    width: 100%
                    height: 0px
                .listOpen
                    animation: listOpen 300ms ease-in-out
                    position: absolute
                    width: calc( 100% - 112px )
                    max-width: 738px
                    z-index: 25
                    top: 312px
                    .service
                        background: var(--txtDark)
                        border: 1px solid var(--milkyBG)
                        text-transform: uppercase
                        display: flex
                        padding: 10px
                        font-size: 20px
                        font-style: normal
                        font-weight: 400
                        align-items: center
                        color: var(--milkyBG)
                    .service:hover
                        animation: serviceAnim 300ms ease-in-out
                        background: var(--accentBlue)
                .checkboxBlock
                    position: relative
                    display: flex
                    margin-top: 32px
                    gap: 16px
                    align-items: center
                    .label
                        position: relative
                        color: var(--milkyBG)
                        z-index: 2
                        cursor: pointer
                        font-size: 20px
                        line-height: normal
                    .checkbox
                        width: 24px
                        height: 24px
                        position: relative
                        z-index: 2
                        background: var(--txtDark) 
                    .checkboxActive
                        cursor: pointer
                        animation: checkboxActive 300ms ease-in-out
                        width: 24px
                        height: 24px
                        position: absolute
                        z-index: 2
                        background: var(--txtDark) 
                    .Active
                        animation: checkboxActive 300ms ease-in-out
                        opacity: 1
                    .notActive
                        animation: checkboxNotActive 300ms ease-in-out
                        opacity: 0
                .btn
                    padding: 15px 25px
                    margin-top: 37px
                    font-size: 14px
                    line-height: 16.8px
                    position: relative
                    z-index: 23
                    background: var(--txtDark) 
                    text-transform: uppercase
                .btnActive
                    border: 1px solid var(--milkyBG)
                    color: var(--milkyBG)
                    cursor: pointer
                .btnActive:hover
                    animation: serviceAnim 300ms ease-in-out
                    background: var(--accentBlue)
                .btnNotActive
                    color: rgba(231, 233, 232, 0.50)
                    border: 1px solid rgba(231, 233, 232, 0.50)
                .dataError
                    color: #D33939 !important
                .dataError:focus
                    color: var(--milkyBG) !important
                    border-bottom: 1px solid #D33939 !important

                .modalSent
                    position: absolute
                    z-index: 100
                    display: flex
                    top: 0
                    left: 0
                    height: 100%
                    width: 100%
                    background-color: rgba(231, 233, 232, 0.6)
                    justify-content: center
                    align-items: center
                    box-sizing: border-box
                    .modalWindow
                        max-width: 441px
                        text-wrap: balance
                        position: relative
                        display: flex
                        align-items: center
                        justify-content: center
                        border-radius: 20px
                        border: 1px solid var(--textBlack, #383838)
                        background: var(--backroundMilky, #E7E9E8)
                        padding: 75px 50px
                        text-align: center
                        font-size: 24px
                        line-height: 28.8px
                        color: var(--textBlack)
                        text-transform: uppercase
                        .modalClose
                            cursor: pointer
                            position: absolute
                            right: 0
                            top: 0
                            width: 27px
                            height: 27px
                            margin: 16px
                

    @keyframes checkboxActive 
        0%
            opacity: 0
        
        100%
            opacity: 1

    @keyframes checkboxNotActive 
        0%
            opacity: 1
        
        100%
            opacity: 0

    @keyframes Open 
        0%
            transform: rotate(0deg)
        
        100%
            transform: rotate(180deg)

    @keyframes Close 
        0%
            transform: rotate(180deg)
        
        100%
            transform: rotate(0deg)

    @keyframes listOpen
        0%
            height: 0px
        
        100%
            height: 204px

    @keyframes serviceAnim 
        0%
            background: var(--txtDark)
        100% 
            background: var(--accentBlue)
                
@keyframes Open 
    0%
        transform: rotate(0deg)
        
    100%
        transform: rotate(180deg)

@keyframes Close 
    0%
        transform: rotate(180deg)
        
    100%
        transform: rotate(0deg)
@keyframes checkboxActive 
    0%
        opacity: 0
        
    100%
        opacity: 1

@keyframes checkboxNotActive 
    0%
        opacity: 1
        
    100%
        opacity: 0

</style>

<script>
import axios from 'axios';


export default {
    data(){
        return{
            checkbox: false,
            open: false,
            services: [],
            name: '',
            nickName: '',
            number: '',
            serviceName: '',
            service: null,
            errorNumber: false,
            errorName: false,
            btnActive: false,
            modalSent: false,
            modalError: false,
            btnPressed: false,
            nameError: true,
            numberError: true,
            serviceError: true,
            protocol: this.$store.state.protocol,
            host: this.$store.state.host
        }
    },
    methods: {
        checkboxFunction(){
            if(this.checkbox===false){
                this.checkbox=true
            }else{
                this.checkbox=false
                this.btnActive=false
            }
            this.update()
        },
        listOpen(){
            this.open=!this.open
        },
        update(){
            if(this.name!='' && this.number!='' && this.service!=null && this.checkbox === true && this.errorName===false && this.errorNumber===false){
                this.btnActive = true
                
            }
        },
        updateNumber(event){
            this.update()
            this.number = this.number.replace(/[A-Za-z]/,'')
            this.number = this.number.replace(/[А-Яа-я]/,'')
            this.number = this.number.replace(/[(!@#$%^&*()=)]/, '')
            if(this.number.length>=14){
                event.preventDefault();
                this.number = this.number.slice(0,14)
            }
        },
        selectService(name){
            const select = this.services.find(item => name === item.name)
            this.serviceName=select.name
            this.service=select.id
            this.update()
            this.open=false
        },

        button(){
            
            if(this.btnActive === true){
                this.btnPressed=true
                if(this.number.length<11){
                    this.errorNumber=true
                    this.btnActive=false
                }
                if(this.name===''){
                    this.errorName=true
                    console.log('name')
                    this.btnActive=false
                }
            }
            
            if(this.btnActive===true && this.errorNumber===false && this.errorName===false){
                axios.post(`${this.protocol}://${this.host}/api/applications`,{
                    "name": this.name,
                    "phone": this.number,
                    "tg_username": this.nickName,
                    "category_id": this.service
                })
                .then(response => {
                    console.log(response)
                    this.modalSent=true
                    this.name = ''
                    this.nickName = ''
                    this.number = ''
                    this.service = null
                    this.serviceName = ''
                    this.checkbox = false
                    this.btnActive = false
                    this.btnPressed=false
                })
                .catch(error => {
                    console.log(error)
                    this.modalError=true
                    this.btnPressed=false
                })
                
            }
        },
        closeModal(){
            this.modalSent=false
            this.modalError=false
        },
        submitForm(){
            
        }
    },
    mounted(){
        axios.get(`${this.protocol}://${this.host}/api/categories`)
        .then(response=>{
            this.services = response.data.data
        })
    },
    updated(){
        if(this.name==='' && this.btnPressed===true){
            this.errorName=true
            this.btnActive=false
        }
        if(this.number.length<11 && this.btnPressed===true){
            this.errorNumber=true
            this.btnActive=false
        }
        if (this.errorNumber===true && this.number.length>=11){
            this.errorNumber=false
        }
        if(this.errorName===true && this.name!=''){
            this.errorName=false
        }
        if(this.errorName === false && this.errorNumber === false && this.btnActive === false && this.btnPressed===true && this.checkbox===true){
            this.btnActive=true
        }
    }
}
</script>