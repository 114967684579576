<script setup>
import headerPage from './header.vue';
import preloaderBlock from './preloaderBlock.vue';
import casesBlock from './casesBlock.vue';
import footerBlock from './footerBlock.vue';
import galleryBlock from './galleryBlock.vue';
</script>
<template>
    <div class="casesPage" :class="{modalActive: gPhotos===true || gPolygraphy===true}">
        <preloaderBlock v-if="!load"></preloaderBlock>
        <headerPage v-if="load"></headerPage>
        <div class="content" v-if="load">
            <div class="hero" :class="{dark: info.theme==='dark', light: info.theme==='light'}">
                <router-link to="/" class="back">
                    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 86 86" fill="none"  class="backIMG" v-if="info.theme==='light'">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.3363 49.8815L18.2434 43.6881C17.8082 43.308 17.8082 42.6918 18.2434 42.3118L25.3363 36.1183C25.7715 35.7382 26.4772 35.7382 26.9125 36.1183C27.3477 36.4984 27.3477 37.1146 26.9125 37.4946L21.7222 42.0267H68.0837V43.9731H21.7222L26.9125 48.5052C27.3477 48.8853 27.3477 49.5015 26.9125 49.8815C26.4772 50.2616 25.7715 50.2616 25.3363 49.8815Z" fill="#383838"/>
                    </svg>
                    <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" viewBox="0 0 86 86" fill="none"  class="backIMG" v-if="info.theme==='dark'">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M25.3363 49.8815L18.2434 43.6881C17.8082 43.308 17.8082 42.6918 18.2434 42.3118L25.3363 36.1183C25.7715 35.7382 26.4772 35.7382 26.9125 36.1183C27.3477 36.4984 27.3477 37.1146 26.9125 37.4946L21.7222 42.0267H68.0837V43.9731H21.7222L26.9125 48.5052C27.3477 48.8853 27.3477 49.5015 26.9125 49.8815C26.4772 50.2616 25.7715 50.2616 25.3363 49.8815Z" fill="#E7E9E8"/>
                    </svg>
                    <div class="txt bf" :class="{light: info.theme==='light', dark: info.theme==='dark'}">на главную</div>
                </router-link>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" class="menuOpen" @click="menuOpen">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M33 13.5H8V10.5H33V13.5ZM33 21.6818H8V18.6818H33V21.6818ZM33 29.5H8V26.5H33V29.5Z" :style="{fill: info.theme==='light' ? '#383838' : '#E7E9E8'}"/>
                </svg>
                
                <div class="logo" :class="{left: info.align==='left', center: info.align==='center'}">
                    <div class="image">
                        <img :src="info.image" alt="">
                    </div>
                    
                    <div class="name bf">{{ info.name }}</div>
                    <div class="type sbf">
                        <div class="tag" v-for="(item,index) in info.tags" :key="index">{{ item }}</div>
                    </div>
                </div>
            </div>
            <div class="info">
                <div class="task">
                    <div class="titleh2">Задача</div>
                    <div class="blockContent">
                        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 93 93" fill="none" class="arrow">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M77.1707 47.4894H4.94727V45.5107H77.1707V47.4894Z" fill="#383838"/>
                            <path d="M88.0537 46.5C88.0537 49.7785 85.3959 52.4362 82.1175 52.4362C78.839 52.4362 76.1813 49.7785 76.1813 46.5C76.1813 43.2216 78.839 40.5638 82.1175 40.5638C85.3959 40.5638 88.0537 43.2216 88.0537 46.5Z" fill="#383838"/>
                        </svg>
                        <div class="txtTask bf">{{ info.task }}</div>
                    </div>
                </div>
                <div class="solution">
                    <div class="titleh2" v-if="info.links.length===0">решение</div>
                    <div class="titleh2" v-if="info.links.length>0">текущие результаты</div>
                    <div class="blockContent" v-for="(item, index) in info.solution" :key="index">
                        <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 93 93" fill="none" class="arrow">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M77.1707 47.4894H4.94727V45.5107H77.1707V47.4894Z" fill="#383838"/>
                            <path d="M88.0537 46.5C88.0537 49.7785 85.3959 52.4362 82.1175 52.4362C78.839 52.4362 76.1813 49.7785 76.1813 46.5C76.1813 43.2216 78.839 40.5638 82.1175 40.5638C85.3959 40.5638 88.0537 43.2216 88.0537 46.5Z" fill="#383838"/>
                        </svg>
                        <div class="txtTask bf">{{ item }}</div>
                    </div>
                </div>
                <div class="buttonBlock" v-if="info.links.length>0">
                    <a :href="info.links[0].url" target="_blank" class="btn titleh2">слушать <img :src="info.links[0].image" alt="да"></a>
                    <a :href="info.links[0].url" target="_blank" class="btnMobile sbf">ссылка на подкаст</a>
                </div>
                <div class="videoContent" v-if="info.videos.length>0" >
                    <div class="title" >
                        <div class="titleh2">Видео-Контент</div>
                        <div class="arrows" v-if="videoArrows">
                            <svg xmlns="http://www.w3.org/2000/svg" width="87" height="87" viewBox="0 0 87 87" fill="none" @click="prev('video')" class="arrow">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M40.7652 56.6071L30.384 46.0342C29.747 45.3853 29.747 44.3334 30.384 43.6846L40.7652 33.1116C41.4023 32.4628 42.4351 32.4628 43.0722 33.1116C43.7092 33.7604 43.7092 34.8124 43.0722 35.4612L35.4757 43.198H54.375V46.5208H35.4757L43.0722 54.2576C43.7092 54.9064 43.7092 55.9583 43.0722 56.6071C42.4351 57.256 41.4023 57.256 40.7652 56.6071Z" fill="#383838"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="87" height="87" viewBox="0 0 87 87" fill="none" @click="next('video')" class="arrow">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.2348 56.6071L56.616 46.0342C57.253 45.3853 57.253 44.3334 56.616 43.6846L46.2348 33.1116C45.5977 32.4628 44.5649 32.4628 43.9278 33.1116C43.2908 33.7604 43.2908 34.8124 43.9278 35.4612L51.5243 43.198H32.625V46.5208H51.5243L43.9278 54.2576C43.2908 54.9064 43.2908 55.9583 43.9278 56.6071C44.5649 57.256 45.5977 57.256 46.2348 56.6071Z" fill="#383838"/>
                            </svg>
                        </div>
                    </div>
                    <div class="carousel" ref="video" 
                    :style="{'--offsetVideoRight': `${-videoOffset}px`, '--offsetVideoMob': `${-videoOffsetMob}px`}" 
                    @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd('video')">
                        <video controls v-for="(item, index) in info.videos" @loadedmetadata="handleImageLoad"  :key="index" class="videoPlayer" ref="elemVideo" :class="{prev: index===(currentVideo-1), dprev: index<=(currentVideo-1)}">
                            <source :src="item.path" type="video/mp4">
                        </video>
                    </div>
                </div>
                
                <div class="photoContent" v-if="info.images.length>0">
                    <galleryBlock v-if="gPhotos===true" :object="info.images" @closeModal="closeGallery" :index="currentId"></galleryBlock>
                    <div class="title">
                        <div class="titleh2">Фото-Контент</div>
                        <div class="arrows" v-if="photoArrows">
                            <svg xmlns="http://www.w3.org/2000/svg" width="87" height="87" viewBox="0 0 87 87" fill="none" @click="prev('photo')" class="arrow">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M40.7652 56.6071L30.384 46.0342C29.747 45.3853 29.747 44.3334 30.384 43.6846L40.7652 33.1116C41.4023 32.4628 42.4351 32.4628 43.0722 33.1116C43.7092 33.7604 43.7092 34.8124 43.0722 35.4612L35.4757 43.198H54.375V46.5208H35.4757L43.0722 54.2576C43.7092 54.9064 43.7092 55.9583 43.0722 56.6071C42.4351 57.256 41.4023 57.256 40.7652 56.6071Z" fill="#383838"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="87" height="87" viewBox="0 0 87 87" fill="none" @click="next('photo')" class="arrow">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.2348 56.6071L56.616 46.0342C57.253 45.3853 57.253 44.3334 56.616 43.6846L46.2348 33.1116C45.5977 32.4628 44.5649 32.4628 43.9278 33.1116C43.2908 33.7604 43.2908 34.8124 43.9278 35.4612L51.5243 43.198H32.625V46.5208H51.5243L43.9278 54.2576C43.2908 54.9064 43.2908 55.9583 43.9278 56.6071C44.5649 57.256 45.5977 57.256 46.2348 56.6071Z" fill="#383838"/>
                            </svg>
                        </div>
                    </div>
                    <div class="carouselPhoto" ref="photo" @click="gPhotos=true" 
                    :style="{'--offsetPhotoRight': `${-photoOffset}px`,'--offsetPhotoMob': `${-photoOffsetMob}px`}"
                    @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd('photo')">
                        <img class="photo" 
                        :src="image.path" alt="" 
                        @load="handleImageLoad"
                        @click="openGallery(index)"
                        v-for="(image, index) in info.images" :key="index" 
                        ref="elemPhoto" 
                        :class="{prev: index===(currentPhoto-1), dprev: index<=(currentPhoto-1)}">
                    </div>
                </div>
                <div class="polyContent" v-if="info.polygraphy.length>0">
                    <galleryBlock v-if="gPolygraphy===true" :object="info.polygraphy" :index="currentId" @closeModal="closeGallery"></galleryBlock>
                    <div class="title">
                        <div class="titleh2">полиграфия</div>
                        <div class="arrows" v-if="polygraphyArrows">
                            <svg xmlns="http://www.w3.org/2000/svg" width="87" height="87" viewBox="0 0 87 87" fill="none" @click="prev('polygraphy')" class="arrow">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M40.7652 56.6071L30.384 46.0342C29.747 45.3853 29.747 44.3334 30.384 43.6846L40.7652 33.1116C41.4023 32.4628 42.4351 32.4628 43.0722 33.1116C43.7092 33.7604 43.7092 34.8124 43.0722 35.4612L35.4757 43.198H54.375V46.5208H35.4757L43.0722 54.2576C43.7092 54.9064 43.7092 55.9583 43.0722 56.6071C42.4351 57.256 41.4023 57.256 40.7652 56.6071Z" fill="#383838"/>
                            </svg>
                            <svg xmlns="http://www.w3.org/2000/svg" width="87" height="87" viewBox="0 0 87 87" fill="none" @click="next('polygraphy')" class="arrow">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M46.2348 56.6071L56.616 46.0342C57.253 45.3853 57.253 44.3334 56.616 43.6846L46.2348 33.1116C45.5977 32.4628 44.5649 32.4628 43.9278 33.1116C43.2908 33.7604 43.2908 34.8124 43.9278 35.4612L51.5243 43.198H32.625V46.5208H51.5243L43.9278 54.2576C43.2908 54.9064 43.2908 55.9583 43.9278 56.6071C44.5649 57.256 45.5977 57.256 46.2348 56.6071Z" fill="#383838"/>
                            </svg>
                        </div>
                    </div>
                    <div class="carouselPoly" ref="polygraphy" @click="gPolygraphy=true"
                    :style="{'--offsetPolygraphyRight': `${-polygraphyOffset}px`,'--offsetPolygraphyMob': `${-polygraphyOffsetMob}px`}"
                    @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd('polygraphy')">
                        <img class="poly" :class="{prev: index===currentPolygraphy-1, dprev: index<=(currentPolygraphy-1)}"
                        :src="image.path" alt=""
                        @click="openGallery(index)" 
                        @load="handleImageLoad"
                        v-for="(image, index) in info.polygraphy" :key="index" 
                        ref="elemPolygraphy">
                    </div>
                </div>
                <div class="effectivenesses" v-if="info.effectivenesses.length>0">
                    <div class="titleh2">эффективность сотрудничества</div>
                    <div class="types">
                        <div class="points bf" v-for="(item,index) in info.effectivenesses" :key="index" :style="{top: `${(211.2*Math.floor(index/2))}px`}">
                            <div class="value">{{ item.value }}</div>
                            <div class="description">{{ item.description }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <casesBlock title="другие кейсы" :currentCase="{casesId}" class="casesBlock"></casesBlock>
            <footerBlock class="footer"></footerBlock>
        </div>
        <div class="noise"></div>
    </div>
</template>

<style lang="sass" scoped>
@media (min-width: 1901px)
    .casesPage
        position: relative
        .content 
            .hero
                width: 100%
                padding: 315px 0 165px 0
                display: flex
                flex-direction: column
                align-items: center
                .back 
                    z-index: 2
                    position: absolute
                    padding: 10px 
                    top: 120px
                    cursor: pointer
                    text-decoration: none
                    gap: 8px
                    display: flex
                    align-items: center
                    left: 16px
                    .txt 
                        text-transform: uppercase
                    
                .back:hover
                    opacity: 0.6
                .menuOpen
                    display: none
                .type
                    z-index: 2
                    display: flex
                    gap: 16px
                    
                .logo  
                    z-index: 2
                    display: flex
                    text-transform: uppercase
                    flex-direction: column
                    img 
                        max-height: 300px
                    .center 
                        align-self: center
                    .tag
                        position: relative
                        z-index: 2
                        margin-top: 32px
                        padding: 10px 30px
                        display: flex
                        text-transform: uppercase
                        border-radius: 40px
                    .tag:nth-child(odd)
                        background: var(--accentPink)
                        color: var(-txtDark)
                    .tag:nth-child(even)
                        background: var(--accentBlue)
                        color: var(--milkyBG)
                .left
                    align-items: flex-start
                .center 
                    align-items: center
            .dark
                background: var(--txtDark)
                .back 
                    .txt 
                        color: var(--milkyBG)
                        background: inherit 
                .logo 
                    .name
                        background: inherit 
                        color: var(--milkyBG)
            .light
                background: var(--milkyBG)
                .back 
                    .txt 
                        color: var(--txtDark)
                        background: inherit 
                .logo 
                    .name
                        background: inherit 
                        color: var(--txtDark)
            .info
                
                .task
                    padding: 77.8px 0 70px 40px
                    background: var(--milkyBG)
                    .titleh2
                        z-index: 2
                        position: relative
                        text-transform: uppercase
                        margin-bottom: 37px 
                    .blockContent
                        position: relative
                        z-index: 2
                        display: flex
                        gap: 16px
                        .txtTask
                            margin-top: 19px
                            max-width: 1203px
                .solution
                    padding: 77.8px 0 14px 40px
                    background: var(--milkyBG)
                    .titleh2
                        z-index: 2
                        position: relative
                        text-transform: uppercase
                        margin-bottom: 37px 
                    .blockContent
                        position: relative
                        z-index: 2
                        display: flex
                        margin-bottom: 56px 
                        margin-top: 0
                        gap: 16px
                        .txtTask
                            margin-top: 19px
                            max-width: 1203px
                .buttonBlock
                    padding: 80px 0
                    display: flex
                    justify-content: center
                    background: var(--milkyBG)
                    .btn
                        display: flex
                        align-items: center
                        gap: 24px    
                        position: relative
                        z-index: 3
                        text-transform: uppercase
                        padding: 20px 40px
                        border: 1px solid var(--txtDark)
                        text-decoration: none
                    .btnMobile
                        display: none
                .videoContent
                    background: var(--milkyBG)
                    padding: 90px 0  70px 40px
                    .title
                        text-transform: uppercase
                        margin-bottom: 56px
                        display: flex
                        justify-content: space-between
                        padding-right: 40px
                        .arrows
                            display: flex
                            gap: 47px
                            .arrow
                                cursor: pointer
                            .arrow:hover 
                                opacity: 0.6
                    .carousel
                        position: relative
                        display: flex
                        z-index: 3
                        gap: 32px
                        transition: transform 0.3s ease-in-out
                        transform: translateX(var(--offsetVideoRight))
                        .videoPlayer
                            
                            max-width: 338px
                            height: 600px  
                        .prev
                            animation: prev 300ms ease-in-out 
                            opacity: 0
                        .dprev
                            opacity: 0
                .photoContent
                    background: var(--milkyBG)
                    padding: 90px 0  70px 40px
                    .title
                        text-transform: uppercase
                        margin-bottom: 56px
                        display: flex
                        justify-content: space-between
                        padding-right: 40px
                        .arrows
                            display: flex
                            gap: 47px
                            .arrow
                                cursor: pointer
                            .arrow:hover 
                                opacity: 0.6
                    .carouselPhoto
                        position: relative
                        display: flex
                        transition: transform 0.3s ease-in-out
                        z-index: 3
                        transform: translateX(var(--offsetPhotoRight))
                        gap: 32px
                        .photo
                            height: 600px
                        .prev
                            animation: prev 300ms ease-in-out 
                            opacity: 0
                        .dprev
                            opacity: 0
                .polyContent
                    background: var(--milkyBG)
                    padding: 90px 0  70px 40px
                    
                    .title
                        text-transform: uppercase
                        margin-bottom: 56px
                        display: flex
                        justify-content: space-between
                        padding-right: 40px
                        .arrows
                            display: flex
                            gap: 47px
                            .arrow
                                cursor: pointer
                            .arrow:hover 
                                opacity: 0.6
                    .carouselPoly
                        transition: transform 0.3s ease-in-out
                        transform: translateX(var(--offsetPolygraphyRight))
                        position: relative
                        display: flex
                        z-index: 3
                        gap: 32px
                        .poly
                            height: 600px
                        .prev
                            animation: prev 300ms ease-in-out 
                            opacity: 0
                        .dprev
                            opacity: 0
                .effectivenesses
                    position: inherit
                    padding: 152px 32px 62px 32px
                    background: var(--milkyBG)
                    .titleh2
                        text-transform: uppercase
                        margin-bottom: 56px
                    .types
                        position: relative
                        .points
                            height: 154px
                            display: flex
                            width: 50%
                            align-items: center
                            gap: 24px
                            padding-bottom: 56px
                            .value
                                position: relative
                                z-index: 2
                                
                                font-size: 128px
                                font-style: normal
                                font-weight: 700
                            .description
                                max-width: 417px
                        .points:nth-child(even) .value 
                            color: var(--accentBlue)
                        .points:nth-child(odd) .value 
                            color: var(--accentPink)
                        .points:nth-child(even)
                            position: absolute
                            left: 940px
            
            .cases
                padding: 90px 0 70px 40px
                font-size: 64px 
                font-style: normal
                font-weight: 400
                line-height: 120%

        .noise
            z-index: 1
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            background: url('../assets/noise.png')
            mix-blend-mode: overlay
            pointer-events: none

@media screen and (min-width: 1216px) and (max-width: 1900px)                 
    .casesPage
        position: relative
        .content 
            display: flex
            flex-direction: column
            align-items: center
            background: var(--milkyBG)
            .hero
                width: 100%
                padding: 194px 0 190px 0
                display: flex
                flex-direction: column
                align-items: center
                .back 
                    z-index: 2
                    position: absolute
                    padding: 10px 
                    top: 86px
                    cursor: pointer
                    text-decoration: none
                    gap: 8px
                    display: flex
                    align-items: center
                    left: calc( 50% - 610px )
                    .txt 
                        text-transform: uppercase
                        font-size: 14px
                        font-weight: 500
                        line-height: 16.8px
                    .backIMG
                        width: 28px
                        height: 28px
                    
                .back:hover
                    opacity: 0.6
                .menuOpen
                    display: none
                .type
                    z-index: 2
                    display: flex
                    gap: 16px
                    
                .logo  
                    z-index: 2
                    display: flex
                    text-transform: uppercase
                    flex-direction: column
                    img 
                        max-height: 300px
                    .center 
                        align-self: center
                    .name 
                        font-size: 24px
                        font-style: normal
                        font-weight: 400
                        line-height: 28.8px
                    .tag
                        position: relative
                        z-index: 2
                        margin-top: 15px
                        padding: 10px 30px
                        display: flex
                        text-transform: uppercase
                        border-radius: 40px
                        font-size: 14px
                        font-style: normal
                        font-weight: 400
                        line-height: 16.8px
                    .tag:nth-child(odd)
                        background: var(--accentPink)
                        color: var(-txtDark)
                    .tag:nth-child(even)
                        background: var(--accentBlue)
                        color: var(--milkyBG)
                .left
                    align-items: flex-start
                .center 
                    align-items: center
            .dark
                background: var(--txtDark)
                .back 
                    .txt 
                        color: var(--milkyBG)
                        background: inherit 
                .logo 
                    .name
                        background: inherit 
                        color: var(--milkyBG)
            .light
                background: var(--milkyBG)
                .back 
                    .txt 
                        color: var(--txtDark)
                        background: inherit 
                .logo 
                    .name
                        background: inherit 
                        color: var(--txtDark)
            .info
                width: 1216px
                .task
                    padding: 56px 0 24px 0
                    background: var(--milkyBG)
                    .titleh2
                        z-index: 2
                        font-size: 36px
                        font-weight: 400
                        line-height: 43.2px
                        position: relative
                        text-transform: uppercase
                        margin-bottom: 40px 
                    .blockContent
                        position: relative
                        z-index: 2
                        display: flex
                        gap: 16px 
                        margin-bottom: 16px
                        .arrow
                            width: 57px
                            height: 57px
                        .txtTask
                            margin-top: 16px
                            max-width: 925px
                            font-size: 24px
                            line-height: 28.8px
                .solution
                    padding: 56px 0 24px 0
                    background: var(--milkyBG)
                    .titleh2
                        z-index: 2
                        font-size: 36px
                        font-weight: 400
                        line-height: 43.2px
                        position: relative
                        text-transform: uppercase
                        margin-bottom: 40px 
                    .blockContent
                        position: relative
                        z-index: 2
                        display: flex
                        gap: 16px
                        margin-bottom: 16px
                        .arrow
                            width: 57px
                            height: 57px
                        .txtTask
                            margin-top: 16px
                            max-width: 925px
                            font-size: 24px
                            line-height: 28.8px
                .buttonBlock
                    padding: 80px 0
                    display: flex
                    justify-content: center
                    background: var(--milkyBG)
                    .btn
                        display: flex
                        align-items: center
                        gap: 24px    
                        position: relative
                        z-index: 3
                        text-transform: uppercase
                        padding: 20px 40px
                        border: 1px solid var(--txtDark)
                        text-decoration: none
                    .btnMobile
                        display: none
                .videoContent
                    background: var(--milkyBG)
                    padding: 120px 0
                    .title
                        text-transform: uppercase
                        margin-bottom: 40px
                        display: flex
                        justify-content: space-between
                        padding: 0px
                        .titleh2
                            font-size: 36px
                            font-weight: 400
                            line-height: 43.2px
                        .arrows
                            display: flex
                            gap: 32px
                            .arrow
                                cursor: pointer
                                width: 57px
                                height: 57px
                            .arrow:hover 
                                opacity: 0.6
                    .carousel
                        position: relative
                        display: flex
                        z-index: 3
                        gap: 32px
                        transition: transform 0.3s ease-in-out
                        transform: translateX(var(--offsetVideoRight))
                        .videoPlayer
                            
                            max-width: 338px
                            height: 600px  
                        .prev
                            animation: prev 300ms ease-in-out 
                            opacity: 0
                        .dprev
                            opacity: 0
                .photoContent
                    background: var(--milkyBG)
                    padding: 56px 0 120px 0
                    .title
                        text-transform: uppercase
                        margin-bottom: 40px
                        display: flex
                        justify-content: space-between
                        padding: 0px
                        .titleh2
                            font-size: 36px
                            font-weight: 400
                            line-height: 43.2px
                        .arrows
                            display: flex
                            gap: 32px
                            .arrow
                                cursor: pointer
                                width: 57px
                                height: 57px
                            .arrow:hover 
                                opacity: 0.6
                    .carouselPhoto
                        position: relative
                        display: flex
                        transition: transform 0.3s ease-in-out
                        z-index: 3
                        transform: translateX(var(--offsetPhotoRight))
                        gap: 32px
                        .photo
                            height: 600px
                        .prev
                            animation: prev 300ms ease-in-out 
                            opacity: 0
                        .dprev
                            opacity: 0
                .polyContent
                    background: var(--milkyBG)
                    padding: 56px 0  120px 0
                    
                    .title
                        text-transform: uppercase
                        margin-bottom: 40px
                        display: flex
                        justify-content: space-between
                        padding: 0px
                        .titleh2
                            font-size: 36px
                            font-weight: 400
                            line-height: 43.2px
                        .arrows
                            display: flex
                            gap: 32px
                            .arrow
                                width: 57px
                                height: 57px
                                cursor: pointer
                            .arrow:hover 
                                opacity: 0.6
                    .carouselPoly
                        transition: transform 0.3s ease-in-out
                        transform: translateX(var(--offsetPolygraphyRight))
                        position: relative
                        display: flex
                        z-index: 3
                        gap: 32px
                        .poly
                            height: 600px
                        .prev
                            animation: prev 300ms ease-in-out 
                            opacity: 0
                        .dprev
                            opacity: 0
                .effectivenesses
                    position: inherit
                    padding: 57px 0 66px 0
                    background: var(--milkyBG)
                    .titleh2
                        text-transform: uppercase
                        margin-bottom: 45px
                        font-size: 36px
                        font-weight: 400
                        line-height: 43.2px
                    .types
                        position: relative
                        width: 100%
                        display: flex
                        row-gap: 48px
                        column-gap: 48px
                        flex-wrap: wrap
                        .points
                            height: 154px
                            display: flex
                            width: calc( 50% - 24px )
                            align-items: center
                            gap: 24px
                            .value
                                position: relative
                                z-index: 2
                                line-height: 112px
                                font-size: 80px
                                font-style: normal
                                font-weight: 700
                            .description
                                max-width: 285px
                                width: 100%
                                font-size: 20px
                                font-weight: 400
                                text-wrap: balance
                                line-height: normal
                        .points:nth-child(even) .value 
                            color: var(--accentBlue)
                        .points:nth-child(odd) .value 
                            color: var(--accentPink)
                        .points:nth-child(even)
                
            .cases
                padding: 90px 0 70px 40px
                font-size: 64px 
                font-style: normal
                font-weight: 400
                line-height: 120%

        .noise
            z-index: 1
            position: absolute
            left: 0
            top: 0
            width: 100%
            height: 100%
            background: url('../assets/noise.png')
            mix-blend-mode: overlay
            pointer-events: none

@media (max-width: 1215px)
    .casesPage 
        position: relative
        z-index: 1
        .header
            z-index: 200
        .content 
            position: inherit
            .hero 
                background: var(--milkyBG)
                box-sizing: border-box
                height: 616px
                width: 100%
                padding: 208px 0 158px 0
                display: flex
                flex-direction: column
                justify-content: center
                align-items: center
                .back 
                    display: none
                .menuOpen
                    position: absolute
                    top: 32px
                    right: 16px
                    z-index: 3
                .logo
                    z-index: 3
                    display: flex
                    flex-direction: column
                    align-items: center
                    position: relative
                    img 
                        
                        max-width: 256px
                    .name 
                        text-transform: uppercase
                        margin-bottom: 16px
                        background: none
                    .type
                        display: flex
                        gap: 8px
                        .tag
                            padding: 10px 20px
                            border-radius: 40px
                        .tag:nth-child(odd)
                            background: var(--accentPink)
                        .tag:nth-child(even)
                            background: var(--accentBlue)
            .dark 
                background: var(--txtDark)
                .logo 
                    .name 
                        color: var(--milkyBG)
            .light 
                background: var(--milkyBG)
                .logo 
                    .name 
                        color: var(--txtDark)
            .info 

                .task 
                    padding: 23px 16px
                    background: var(--milkyBG)
                    .blockContent
                        display: flex
                        position: relative
                        z-index: 3
                        .arrow
                            margin-top: 2px
                            max-width: 24px
                            height: 24px
                        .txtTask 
                            margin-top: 6px
                            text-align: left
                .solution
                    background: var(--milkyBG) 
                    padding: 23px 16px 48px 16px
                    .blockContent
                        position: relative
                        z-index: 3
                        display: flex
                        .arrow
                            margin-top: 2px
                            max-width: 24px
                            height: 24px
                        .txtTask 
                            margin-top: 6px
                            text-align: left
                .buttonBlock 
                    display: flex
                    justify-content: center
                    padding: 32px 0 56px 0
                    background: var(--milkyBG) 
                    .btn 
                        display: none
                    .btnMobile 
                        position: relative
                        z-index: 3
                        border: 1px solid var(--txtDark)
                        padding: 20px 40px
                        text-transform: uppercase
                        color: var(--txtDark)
                        text-decoration: none
                        font-size: 24px
                        font-weight: 500

                .videoContent
                    background: var(--milkyBG) 
                    padding: 24px 0 48px 16px
                    .title
                        margin-bottom: 8px
                        .arrows
                            display: none
                    .carousel
                        position: relative
                        z-index: 3
                        display: flex
                        gap: 8px
                        transition: transform 0.3s ease-in-out
                        transform: translateX(var(--offsetVideoMob))
                        .videoPlayer 
                            height: 375px
                            animation: new 300ms ease-in-out
                        .prev
                            animation: prev 300ms ease-in-out 
                            opacity: 0
                .photoContent
                    background: var(--milkyBG)
                    padding: 24px 0 48px 16px
                    .title
                        margin-bottom: 8px
                        .arrows
                            display: none
                    .carouselPhoto
                        position: relative
                        transition: transform 0.3s ease-in-out
                        z-index: 3
                        display: flex
                        gap: 8px
                        transform: translateX(var(--offsetPhotoMob))
                        .photo 
                            height: 375px
                            animation: new 300ms ease-in-out
                        .prev
                            animation: prev 300ms ease-in-out 
                            opacity: 0
                .polyContent
                    background: var(--milkyBG)
                    padding: 24px 0 48px 16px
                    .title
                        margin-bottom: 8px
                        .arrows
                            display: none
                    .carouselPoly
                        position: relative
                        z-index: 3
                        display: flex
                        gap: 8px
                        transition: transform 0.3s ease-in-out
                        transform: translateX(var(--offsetPolygraphyMob))
                        .poly 
                            height: 375px
                            animation: new 300ms ease-in-out
                        .prev
                            animation: prev 300ms ease-in-out 
                            opacity: 0
                .effectivenesses 
                    background: var(--milkyBG)
                    padding: 24px 16px 48px 16px
                    .titleh2 
                        margin-bottom: 16px
                    .types
                        display: flex
                        flex-direction: column
                        align-items: center
                        position: relative
                        z-index: 3
                        .points 
                            display: flex
                            flex-direction: column
                            align-content: center
                            .value 
                                
                                font-size: 64px 
                                font-weight: 700
                                line-height: 120%
                                text-transform: uppercase
                            .description 
                                max-width: 204px
                        .points:nth-child(odd) .value
                                color: var(--accentPink)
                        .points:nth-child(even) .value
                                color: var(--accentBlue)
        .noise
            position: absolute
            width: 100%
            height: 100%
            top: 0
            left: 0
            background: url('../assets/noise.png')
            mix-blend-mode: overlay
            pointer-events: none
    .modalActice
        overflow: hidden



@keyframes prev
    0%
        opacity: 1
    100% 
        opacity: 0
@keyframes new
    0%
        opacity: 0
    100% 
        opacity: 1
</style>

<script>
import axios from 'axios';

export default {
    data(){
        return{
            protocol: this.$store.state.protocol,
            host: this.$store.state.host,
            info: [],
            casesId: this.$route.params.id,
            load: false,
            currentVideo: 0,
            videoOffset: 0,
            videoOffsetMob: 0,
            currentPhoto: 0,
            photoOffset: 0,
            photoOffsetMob: 0,
            currentPolygraphy: 0,
            polygraphyOffset: 0,
            polygraphyOffsetMob: 0,
            photoArrows: false,
            videoArrows: false,
            polygraphyArrows: false,
            btnReady: true,
            menuReady: true,
            podcast: false,
            gPhotos: false,
            gPolygraphy: false,
            currentId: null
        }
    },
    methods:{
        openGallery(index){
            this.currentId=index
            this.gPhotos=true
        },
        closeGallery(){
            
            this.gPhotos=false
            this.gPolygraphy=false
        },
        Arrows(){
            const screenWidth = window.innerWidth;
            
            if(this.info.images.length>0){
                const photoBlock = this.$refs.photo.scrollWidth
                if(photoBlock > screenWidth){
                    this.photoArrows = true
                }
                if(screenWidth>1215 && screenWidth<=1900 && photoBlock>1216){
                    this.photoArrows = true
                }
            }
            if(this.info.videos.length>0){
                const videoBlock = this.$refs.video.scrollWidth
                if(videoBlock > screenWidth){
                    this.videoArrows = true
                }
                if(screenWidth>1215 && screenWidth<=1900 && videoBlock>1216){
                    this.videoArrows = true
                }
            }
            if(this.info.polygraphy.length>0){
                const polygraphyBlock = this.$refs.polygraphy.scrollWidth
                if(polygraphyBlock > screenWidth){
                    this.polygraphyArrows = true
                } 
                if(screenWidth>1215 && screenWidth<=1900 && polygraphyBlock>1216){
                    this.polygraphyArrows = true
                }
            }
            
        },
        next(method){
            if(this.btnReady===true){
                this.btnReady=false
                if(method==='video'){
                    const block = this.$refs.elemVideo
                    if(this.currentVideo<block.length-1){
                        this.videoOffset+=(block[this.currentVideo].offsetWidth+32)
                        this.videoOffsetMob+=(block[this.currentVideo].offsetWidth+8)
                        this.currentVideo+=1
                    }
                }
                if(method==='photo'){
                    const block = this.$refs.elemPhoto
                    if(this.currentPhoto<block.length-1){
                        this.photoOffset+=(block[this.currentPhoto].offsetWidth+32)
                        this.photoOffsetMob+=(block[this.currentPhoto].offsetWidth+8)
                        this.currentPhoto+=1
                    }
                    
                }
                if(method==='polygraphy'){
                    const block = this.$refs.elemPolygraphy
                    if(this.currentPolygraphy<block.length-1){
                        this.polygraphyOffset+=(block[this.currentPolygraphy].offsetWidth+32)
                        this.polygraphyOffsetMob+=(block[this.currentPolygraphy].offsetWidth+8)
                        this.currentPolygraphy+=1
                    }
                }
                setTimeout(()=>{
                    this.btnReady=true
                },300)
            }
        },
        prev(method){
            if(this.btnReady===true){
                this.btnReady=false
                if(method==='video'){
                    const block = this.$refs.elemVideo
                    if(this.currentVideo>0){
                        this.videoOffset-=(block[this.currentVideo-1].offsetWidth+32)
                        this.videoOffsetMob-=(block[this.currentVideo-1].offsetWidth+8)
                        this.currentVideo-=1
                    }
                    
                }
                if(method==='photo'){
                    const block = this.$refs.elemPhoto
                    if(this.currentPhoto>0){
                        this.photoOffset-=(block[this.currentPhoto-1].offsetWidth+32)
                        this.photoOffsetMob-=(block[this.currentPhoto-1].offsetWidth+8)
                        this.currentPhoto-=1
                    }
                    
                }
                if(method==='polygraphy'){
                    const block = this.$refs.elemPolygraphy
                    if(this.currentPolygraphy>0){
                        this.polygraphyOffset-=(block[this.currentPolygraphy-1].offsetWidth+32)
                        this.polygraphyOffsetMob-=(block[this.currentPolygraphy-1].offsetWidth+8)
                        this.currentPolygraphy-=1
                    }
                }
                setTimeout(()=>{
                    this.btnReady=true
                },300)
            }
        },
        onTouchStart(e) {
            this.startX = e.touches[0].clientX;
        },
        onTouchMove(e) {
            this.endX = e.touches[0].clientX;
        },
        onTouchEnd(method) {
            const diff = this.startX - this.endX;
            if (Math.abs(diff) > 50 && this.endX!=0 && this.btnReady === true) {
                if (diff > 0) {
                    this.next(method);
                } else {
                    this.prev(method);
                }
            }
            this.startX = 0
            this.endX = 0
        },
        menuOpen(){
            if (this.menuReady===true){
                this.$store.commit('headerMobile')
            }
        },
        handleImageLoad() {
            this.Arrows();
        }
    },
    mounted(){
        axios.post(`${this.protocol}://${this.host}/api/cases/search`,{
            includes: [
                {
                    relation: "links"
                },
                {
                    relation: "effectivenesses"
                },
                {
                    relation: "images"
                },
                {
                    relation: "videos"
                },
                {
                    relation: "polygraphy"
                }
            ],
            filters: [
                {
                    field: "is_archived",
                    operator: "=",
                    value: false
                },
                {
                    "field": "id",
                    "operator": "=",
                    "value": this.casesId
                }
            ]
        })
        .then(response=>{
            this.info = response.data.data[0]
            if(this.info.solution.length>0){
                let string = ''
                let array = []
                string = this.info.solution.replace(/<\/li>/g,' ')
                string = string.replace(/<li>/g, '/n')
                string = string.replace(/<\/p>/g,'/n') 
                string = string.replace(/<p>/g,'/n')
                string = string.replace(/<\/ol>/g,'')
                string = string.replace(/<ol>/g, '')
                string = string.split('/n')
                for(let elem of string){
                    if (elem!=''){
                        array.push(elem)
                    }
                }
                this.info.solution = array
            }
            this.load=true
        })

    },
    watch: {
        $route(to) {
            this.load=false
            this.casesId = to.params.id,
            axios.post(`${this.protocol}://${this.host}/api/cases/search`,{
                includes: [
                    {
                        relation: "links"
                    },
                    {
                        relation: "effectivenesses"
                    },
                    {
                        relation: "images"
                    },
                    {
                        relation: "videos"
                    },
                    {
                        relation: "polygraphy"
                    }
                ],
                filters: [
                    {
                        field: "is_archived",
                        operator: "=",
                        value: false
                    },
                    {
                        "field": "id",
                        "operator": "=",
                        "value": to.params.id
                    }
                ]
            })
            .then(response=>{
                this.info = response.data.data[0]
                let string = ''
                let array = []
                string = this.info.solution.replace(/<\/li>/g,' ')
                string = string.replace(/<li>/g, '/n')
                string = string.replace(/<\/p>/g,'/n') 
                string = string.replace(/<p>/g,'/n')
                string = string.replace(/<\/ol>/g,'')
                string = string.replace(/<ol>/g, '')
                string = string.split('/n')
                for(let elem of string){
                    if (elem!=''){
                        array.push(elem)
                    }
                }
                this.info.solution = array
                this.load=true
                this.currentVideo= 0
                this.videoOffset= 0
                this.videoOffsetMob= 0
                this.currentPhoto= 0
                this.photoOffset= 0
                this.photoOffsetMob= 0
                this.currentPolygraphy= 0
                this.polygraphyOffset= 0
                this.polygraphyOffsetMob= 0
            })
        }
    }
}
</script>