import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    headerActive: false,
    protocol: 'https',
    host: 'widen-agency.ru'
  },
  getters: {
  },
  mutations: {
    headerMobile(state){
      state.headerActive=!state.headerActive
    },
  },
  actions: {
  },
  modules: {
  }
})
