<template>
    <div class="gallery">
        <svg xmlns="http://www.w3.org/2000/svg" width="87" height="87" viewBox="0 0 87 87" fill="none" @click="back" :class="{notActive: currentPhoto===0, back: currentPhoto!=0}">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M40.7652 56.6071L30.384 46.0342C29.747 45.3853 29.747 44.3334 30.384 43.6846L40.7652 33.1116C41.4023 32.4628 42.4351 32.4628 43.0722 33.1116C43.7092 33.7604 43.7092 34.8124 43.0722 35.4612L35.4757 43.198H54.375V46.5208H35.4757L43.0722 54.2576C43.7092 54.9064 43.7092 55.9583 43.0722 56.6071C42.4351 57.256 41.4023 57.256 40.7652 56.6071Z" fill="#383838"/>
        </svg>
        <div class="photos" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
            <div class="current"><img :src="object[currentPhoto].path" alt="" class="currentPhoto"></div>
            <div class="others" > 
                <img :src="item.path" alt="" @click="currentPhoto=index"
                class="otherPhotos" :class="{current: index===currentPhoto, 
                mobile: (index>(currentPhoto+3) && currentPhoto<(item.length-3)) || (index<(currentPhoto-3) && currentPhoto>2)}"
                v-for="(item, index) in object" :key="index" >
            </div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="87" height="87" viewBox="0 0 87 87" fill="none" @click="next" :class="{notActive: currentPhoto===(object.length-1), next: currentPhoto<(object.length-1)}">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M46.2348 56.6071L56.616 46.0342C57.253 45.3853 57.253 44.3334 56.616 43.6846L46.2348 33.1116C45.5977 32.4628 44.5649 32.4628 43.9278 33.1116C43.2908 33.7604 43.2908 34.8124 43.9278 35.4612L51.5243 43.198H32.625V46.5208H51.5243L43.9278 54.2576C43.2908 54.9064 43.2908 55.9583 43.9278 56.6071C44.5649 57.256 45.5977 57.256 46.2348 56.6071Z" fill="#383838"/>
        </svg>
        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none" class="close" @click="closeModal">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M41.3091 13.363L13.3628 41.3093L10.4165 38.363L38.3628 10.4167L41.3091 13.363Z" fill="#383838"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.4165 13.363L38.3628 41.3093L41.3091 38.363L13.3628 10.4167L10.4165 13.363Z" fill="#383838"/>
        </svg>
        <div class="noise"></div>
    </div>
</template>

<style lang="sass" scoped>
@media (min-width: 1100px)
    .gallery
        box-sizing: border-box
        position: fixed
        z-index: 10000
        top: 0
        left: 0
        width: 100%
        height: 100vh
        background: var(--milkyBG)
        display: flex
        padding: 53px 104px
        justify-content: space-between
        align-items: center
        .close
            position: absolute
            z-index: 2
            top: 40px
            right: 58px
        .close:hover
            opacity: 0.6
        .next
            position: relative
            z-index: 2
        .back   
            position: relative
            z-index: 2
        .next:hover
            opacity: 0.6
        .back:hover
            opacity: 0.6
        .notActive
            opacity: 0.3
            position: relative
            z-index: 2
        .photos
            display: flex
            position: relative
            z-index: 2
            flex-direction: column
            gap: 9px
            .current
                height: 673px 
                position: relative
                display: flex
                justify-content: center
                .currentPhoto
                    height: 100%
            .others
                display: flex
                justify-content: center
                gap: 6px
                .otherPhotos
                    height: 106px
                .current 
                    border: 5px solid var(--txtDark)
        .noise
            position: absolute
            z-index: 1
            top: 0
            left: 0
            width: 100%
            height: 100%
            background: url('../assets/noise.png')
            mix-blend-mode: overlay 
@media (max-width: 1100px)
    .gallery
        box-sizing: border-box
        position: fixed
        z-index: 10000
        top: 0
        left: 0
        width: 100%
        height: 100vh
        background: black
        display: flex
        align-items: center
        .close
            position: absolute
            z-index: 2
            top: 10px
            right: 10px
            path 
                fill: var(--milkyBG)
        .next
            display: none
        .back
            display: none
        .notActive
            display: none 
        .photos 
            display: flex
            position: relative
            width: 100%
            z-index: 2
            flex-direction: column
            gap: 9px
            .current 
                position: relative
                width: 100%
                .currentPhoto
                    width: 100%
            .others
                display: none

@media screen and (min-width: 1216px) and (max-width: 1900px)
    .gallery
        box-sizing: border-box
        position: fixed
        z-index: 10000
        top: 0
        left: 0
        width: 100%
        height: 100vh
        background: var(--milkyBG)
        display: flex
        padding: 53px 104px
        justify-content: space-between
        align-items: center
        .close
            position: absolute
            z-index: 2
            top: 40px
            right: 58px
        .close:hover
            opacity: 0.6
        .next
            position: relative
            z-index: 2
            top: -50px
        .back   
            position: relative
            z-index: 2
            top: -50px
        .next:hover
            opacity: 0.6
        .back:hover
            opacity: 0.6
        .notActive
            opacity: 0.3
            position: relative
            z-index: 2
            top: -50px
        .photos
            display: flex
            position: relative
            width: 100%
            z-index: 2
            flex-direction: column
            gap: 9px
            .current
                height: 673px 
                position: relative
                display: flex
                justify-content: center
                align-items: center
                width: 100%
                .currentPhoto
                    max-width: 100%
                    height: max-content
            .others
                display: flex
                justify-content: center
                gap: 6px
                .otherPhotos
                    height: 106px
                .current 
                    border: 5px solid var(--txtDark)
                    width: auto

        .noise
            position: absolute
            z-index: 1
            top: 0
            left: 0
            width: 100%
            height: 100%
            background: url('../assets/noise.png')
            mix-blend-mode: overlay 
</style>

<script>
export default {
    data(){
        return{
            currentPhoto: 0,
            startX: 0,
            endX: 0
        }
    },
    methods: {
        next(){
            if(this.currentPhoto<this.object.length-1){
                this.currentPhoto+=1
            }
        },
        back(){
            if(this.currentPhoto>0){
                this.currentPhoto-=1
            }
        },
        closeModal(){
            this.$emit('closeModal')
        },
        onTouchStart(e) {
            this.startX = e.touches[0].clientX;
        },
        onTouchMove(e) {
            this.endX = e.touches[0].clientX;
        },
        onTouchEnd() {
            const diff = this.startX - this.endX;
            if (Math.abs(diff) > 50 && this.endX!=0) {
                if (diff > 0) {
                    this.next()
                } else {
                    this.back()
                }
            }
            this.startX = 0
            this.endX = 0
        },
    },
    props: {
        object:{
            type: Array,
            required: true
        },
        index: {
            type: Number,
            required: true
        }
    },
    mounted(){
        this.currentPhoto=this.index
    }
}
</script>