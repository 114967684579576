<template>
    <div class="mediaMain">
        <div class="content">
            <div class="mediaInfo">
                <div class="right">
                    <img src="../assets/media.png" alt="Пошла торговля" class="mediaIMG">
                    <svg xmlns="http://www.w3.org/2000/svg" width="93" height="93" viewBox="0 0 93 93" fill="none" class="arrow">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M77.1707 47.4894H4.94727V45.5107H77.1707V47.4894Z" fill="#383838"/>
                        <path d="M88.0537 46.5C88.0537 49.7785 85.3959 52.4362 82.1175 52.4362C78.839 52.4362 76.1813 49.7785 76.1813 46.5C76.1813 43.2216 78.839 40.5638 82.1175 40.5638C85.3959 40.5638 88.0537 43.2216 88.0537 46.5Z" fill="#383838"/>
                    </svg>
                </div>
                <div class="left">
                    <div class="titleh1">Мы в медиа</div>
                    <div class="more bf"><div class="txt">Перенимаем опыт у коллег: ведем подкаст про бизнес и маркетинг «Пошла торговля», в котором с представителями таких брендов, 
                        как Авиасейлс, Skyeng, Twinby и другими обсуждаем, на чём строится их контент-маркетинг. Лучшие решения применям в работе с клиентами WiDEN.</div>
                        
                    </div>
                    <div class="btns sbf">
                        <a href="https://music.yandex.ru/album/26059784?dir=desc&activeTab=about" target="_blank" class="btn">слушать на яндекс музыке</a>
                        <a href="
https://podcasts.apple.com/us/podcast/%D0%BF%D0%BE%D1%88%D0%BB%D0%B0-%D1%82%D0%BE%D1%80%D0%B3%D0%BE%D0%B2%D0%BB%D1%8F/id1690394697" target="_blank" class="btn">слушать на Apple Podcasts</a>
                    </div>
                </div>
            </div>
            <div class="guests">
                <div class="title titleh1">Гости подкаста</div>
                <div class="images">
                    <div class="img" v-for="(item, id) in images" :key="id"><img :src="item" alt=""></div>
                </div>
            </div>
        </div>
        <div class="noise"></div>
    </div>
</template>
<style lang="sass" scoped>
@media (min-width: 1901px) 
    .mediaMain
        width: 100%
        position: relative
        .content 
            position: relative
            width: 100% 
            box-sizing: border-box
            padding: 131px 72px 0 144px
            background: var(--milkyBG)
            .mediaInfo
                display: flex
                gap: 122px
                
                .right
                    z-index: 6
                    position: relative
                    .mediaIMG
                        position: relative
                        width: 644px
                    .arrow
                        z-index: 2
                        position: absolute
                        top: 222px
                        right: -88px 
                        width: 93px
                .left
                    position: relative
                    z-index: 5
                    .titleh1
                        margin: 60px 0 34px 0
                        text-transform: uppercase
                    .more
                        position: relative
                        max-width: 813px
                        
                    .btns 
                        margin-top: 34px
                        display: flex
                        gap: 20px
                        .btn 
                            animation: btnReverse 300ms ease-in-out
                            cursor: pointer
                            padding: 20px 40px
                            text-transform: uppercase
                            border: 1px solid var(--txtDark)
                            text-decoration: none
                            color: var(--txtDark)
                        .btn:hover 
                            animation: btnAnim 300ms ease-in-out
                            background: var(--accentBlue)
                            color: var(--milkyBG)
            .guests
                z-index: 6
                position: relative
                width: 100%
                padding: 184px 0
                .title 
                    text-transform: uppercase
                .images 
                    margin-top: 104px
                    display: flex
                    flex-wrap: wrap
                    gap: 123px
                    justify-content: center
                    .img 
                        filter: grayscale(100%)

    @keyframes btnAnim 
        0%
            background: var(--milkyBG) 
            color: var(--txtDark)
        100% 
            background: var(--accentBlue)
            color: var(--milkyBG)  

    @keyframes btnReverse 
        0%
            background: var(--accentBlue)
            color: var(--milkyBG)
        100% 
            background: var(--milkyBG)
            color: var(--txtDark) 


@media screen and (min-width: 1216px) and (max-width: 1900px)
    .mediaMain
        width: 100%
        position: relative
        .content 
            position: relative
            width: 100% 
            box-sizing: border-box
            padding: 164px 0
            display: flex
            flex-direction: column
            align-items: center
            background: var(--milkyBG)
            .mediaInfo
                display: flex
                gap: 75px
                margin-bottom: 165px 
                .right
                    z-index: 6
                    position: relative
                    .mediaIMG
                        position: relative
                        z-index: 3
                        width: 471px
                    .arrow
                        z-index: 2
                        position: absolute
                        top: 170px
                        right: -48px 
                        width: 54px
                        height: 54px
                .left
                    position: relative
                    z-index: 5
                    .titleh1
                        margin: 75px 0 32px 0
                        text-transform: uppercase
                        font-size: 64px
                        font-weight: 300
                        line-height: 120%
                    .more
                        position: relative
                        font-weight: 400
                        font-size: 20px
                        line-height: normal
                        max-width: 670px
                    .btns 
                        margin-top: 32px
                        display: flex
                        gap: 27px
                        .btn 
                            animation: btnReverse 300ms ease-in-out
                            cursor: pointer
                            padding: 15px 30px
                            text-transform: uppercase
                            border: 1px solid var(--txtDark)
                            text-decoration: none
                            color: var(--txtDark)
                            font-size: 14px
                            font-weight: 500
                            line-height: 16.8px

                        .btn:hover 
                            animation: btnAnim 300ms ease-in-out
                            background: var(--accentBlue)
                            color: var(--milkyBG)
            .guests
                z-index: 6
                position: relative
                max-width: 1220px
                padding: 104px 0
                .title 
                    text-transform: uppercase
                    font-size: 64px
                    font-weight: 300
                    line-height: 120%
                    margin-bottom: 55px
                .images
                    display: flex
                    flex-wrap: wrap
                    row-gap: 55px
                    column-gap: 136px
                    justify-content: center
                    .img 
                        max-width: 314px
                        position: relative
                        filter: grayscale(100%)
                        display: flex
                        align-items: center
                        justify-content: center
                        img
                            position: relative
                            width: 100%

@keyframes btnAnim 
    0%
        background: var(--milkyBG) 
        color: var(--txtDark)
    100% 
        background: var(--accentBlue)
        color: var(--milkyBG)  

@keyframes btnReverse 
    0%
        background: var(--accentBlue)
        color: var(--milkyBG)
    100% 
        background: var(--milkyBG)
        color: var(--txtDark)  

@media (max-width: 1216px) 
    .mediaMain
        width: 100%
        position: relative
        .content 
            position: relative
            width: 100%
            box-sizing: border-box
            padding: 0 16px 21px 16px
            background: var(--milkyBG)
            .mediaInfo
                display: flex
                flex-direction: column
                gap: 0px
                .right
                    z-index: 6
                    position: relative
                    margin-top: 123px
                    display: flex
                    flex-direction: column
                    align-items: center
                    img 
                        width: 100%
                    .arrow 
                        transform: rotate(90deg)
                        position: relative
                        top: -20px
                .left 
                    position: inherit
                    z-index: 5
                    .titleh1
                        position: absolute
                        top: 56px
                    .more 
                        
                        display: flex
                        position: relative
                        align-items: center
                        flex-direction: column
                        .txt 
                            order: 2
                        .arrow 
                            order: 1
                            position: absolute
                            transform: rotate(90deg)
                            top: -25px
                            width: 70px
                    .btns 
                        margin-top: 24px
                        display: flex
                        flex-direction: column
                        gap: 16px
                        .btn 
                            animation: btnReverse 300ms ease-in-out
                            display: flex
                            justify-content: center
                            padding: 20px 0
                            font-size: 14px
                            border: 1px solid var(--txtDark)
                            font-weight: 500
                            text-transform: uppercase
                            text-decoration: none
                            color: var(--txtDark)
                        
            .guests
                position: relative
                margin-top: 80px
                z-index: 5
                .title 
                    text-transform: uppercase
                .images
                    margin: 12px 0 21px 0
                    position: relative
                    display: flex
                    flex-wrap: wrap
                    justify-content: space-between
                    .img 
                        margin-top: 32px
                        position: relative
                        width: calc( (100% - 42px)/2 )
                        object-fit: cover
                        img 
                            position: relative
                            width: 100%
                            object-fit: cover
                            filter: grayscale(100%) 


</style>


<script>
export default {
    data(){
        return{
            images: ['./media/aviasales.png','./media/skyeng.png','./media/choux.png','./media/viva.png','./media/twin.png']
        }
    }
}
</script>