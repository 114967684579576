<template>
    <div class="teamMain">
        <div class="team">
            <div class="team__title titleh1">
                Команда
            </div>
            <div class="wrapper" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
                <div class="team__info" :class="{update: update===true, newUpdate: update===false}">
                    <div class="team__info team__info--name titleh2">
                        {{ images1[currentIndex].first_name+ ' ' + images1[currentIndex].surname}}
                    </div>
                    <div class="team__info team__info--pos sbf">
                        {{ images1[currentIndex].position }}
                    </div>
                </div>
                <div class="team__carousel" :class="{nextAnim: nxt===true, prevAnim: prev===true}" 
                
                :style="{'--main-right-prev': `${offset-356}px`,'--main-left-prev': `${offset+356}px`,'--main-right-next': `${offset}px`,
                '--main-right-prev-mob': `${offsetMob-117}px`,'--main-left-prev-mob': `${offsetMob+117}px`,'--main-right-next-mob': `${offsetMob}px`,
                '--main-right-prev-lap': `${offsetLap-288}px`,'--main-left-prev-lap': `${offsetLap+288}px`,'--main-right-next-lap': `${-offsetLap}px`}">
                    <img :src="image.image" 
                    class="team__carousel team__carousel--images image" 
                    alt="Carousel Image" 
                    v-for="(image, index) in images1" 
                    :key="index" 
                    :class="{'current': index===currentIndex, 'prev': (index===currentIndex-1 && move===1)||(index===currentIndex+1 && move===2)}">
                </div>
                <div class="team__arrows">
                    <img src="../assets/icons/arrow.svg" alt="Влево" class="team__arrows team__arrows--left" @click="prevMember">
                    <img src="../assets/icons/arrow.svg" alt="Вправо" class="team__arrows team__arrows--right" @click="nextMember">
                </div>
                
            </div>
            
        </div>
        <div class="noise"></div>
    </div>
    
</template>

<style lang="sass" scoped>
@media (min-width: 1901px)
    .teamMain
        padding-top: 88px
        background: var(--milkyBG) 
        position: relative
        overflow: hidden
    .team
        position: relative
        z-index: 10
        padding: 56px 0 174px 32px
        &__title
            margin: 0 0 8px 112px
            text-transform: uppercase
        .wrapper
            position: relative
            height: 658px
            overflow: hidden
            .team__carousel
                position: relative
                right: var(--main-right-next)
                display: flex
                flex-wrap: nowrap
                gap: 40px
                max-height: 644px
                &--images
                    right: 0
                    margin-top: 290px
                    align-self: flex-end
                    pointer-events: none
                .current
                    animation: currentAnim 300ms ease-in-out
                    margin-top: 38px
                    height: 658px
                    width: 512px
                .prev 
                    animation: previous 300ms ease-in-out
            .nextAnim
                animation: nextAnim 300ms ease-in-out
            .prevAnim
                animation: prevAnim 300ms ease-in-out 
        &__arrows
            position: relative
            display: flex
            bottom: 540px 
            z-index: 20
            justify-content: space-between
            width: 100%
            &--left 
                margin-left: 24px
                left: 0
                padding: 0
                width: 87px
                cursor: pointer
                bottom: 0
                transform: rotate(180deg)
            &--left:hover 
                opacity: 0.6
            &--right
                margin-right: 56px
                padding: 0 
                width: 87px
                cursor: pointer
                bottom: 0
            &--right:hover
                opacity: 0.6
        &__info
            text-transform: uppercase
            position: absolute
            z-index: 10
            width: 100%
            display: flex
            padding-left: 945px
            flex-direction: column
            top: 0
            gap: 30px
            &--name 
                padding: 0
                font-family: Jost
                font-size: 64px
                font-weight: 400
                line-height: 76.8px
                text-align: left
                bottom: 0px
                position: relative
            &--pos 
                bottom: 0px
                position: relative
                padding: 0
                font-family: Jost
                font-size: 24px
                font-weight: 500
                line-height: 28.8px
                text-align: left
        .update
            animation: txtUpdate 300ms ease-in-out
        .newUpdate
            animation: txtNewUpdate 300ms ease-in-out
    @keyframes nextAnim 
        0%
            right: var(--main-right-prev)
        
        100%
            right: var(--main-right-next)

    @keyframes prevAnim 
        0%
            right: var(--main-left-prev)
        
        100%
            right: var(--main-right-next)   

    @keyframes currentAnim 
        0%
            width: 316px
            height: 406px
        100%
            width: 512px
            height: 658px
    @keyframes previous 
        0% 
            width: 512px
            height: 658px
        100%
            width: 316px
            height: 406px

    @keyframes txtUpdate 
        0%
            opacity: 1
        100%
            opacity: 0

    @keyframes txtNewUpdate
        0% 
            opacity: 0
        100% 
            opacity: 1

@media (max-width: 1215px)
    .teamMain
        background: var(--milkyBG) 
        position: relative
        overflow: hidden
        .team 
            padding: 56px 0
            &__title 
                position: relative
                z-index: 10
                text-transform: uppercase
                margin: 0 0 161px 16px
            .wrapper
                z-index: 10
                position: relative
                display: flex
                height: 427px
                flex-direction: column
                justify-content: space-between
                gap: 23px
                .team__carousel
                    height: 305px
                    order: 1
                    position: relative
                    display: flex
                    flex-wrap: nowrap
                    right: calc( 117px + 117px - ( 100vw - 237px ) / 2 + var(--main-right-next-mob))
                    gap: 10px
                    max-height: 382px
                    align-items: flex-end
                    &--images
                        height: auto
                        right: 0
                        align-self: flex-end
                        pointer-events: none
                        width: 107px
                    .current
                        right: 0
                        animation: currentAnim 300ms ease-in-out
                        height: 305px
                        width: 237px
                    .prev
                        right: 0
                        animation: previous 300ms ease-in-out
                        width: 107px
                .nextAnim
                    animation: nextAnim 300ms ease-in-out
                .prevAnim
                    animation: prevAnim 300ms ease-in-out 
                .team__info
                    order: 2
                    display: flex
                    flex-direction: column
                    gap: 8px
                    align-items: center
                    &--pos
                        color: #000
                        font-size: 14px
                        text-transform: uppercase
                .update
                    animation: txtUpdate 300ms ease-in-out
                .newUpdate
                    animation: txtNewUpdate 300ms ease-in-out
                .team__arrows
                    display: none 
            
    @keyframes currentAnim 
        0%
            height: 137px
            width: 107px
        100%
            height: 305px
            width: 237px

    @keyframes previous 
        0% 
            height: 305px
            width: 237px
        100%
            height: 137px
            width: 107px
                
    @keyframes txtUpdate 
        0%
            opacity: 1
        100%
            opacity: 0

    @keyframes txtNewUpdate
        0% 
            opacity: 0
        100% 
            opacity: 1          

    @keyframes nextAnim 
        0%
            right: calc( 117px + 117px - ( 100vw - 237px ) / 2 + var(--main-right-prev-mob))
        
        100%
            right: calc( 117px + 117px - ( 100vw - 237px ) / 2 + var(--main-right-next-mob))

    @keyframes prevAnim 
        0%
            right: calc( 117px + 117px - ( 100vw - 237px ) / 2 + var(--main-left-prev-mob)) 
            
        
        100%
            right: calc( 117px + 117px - ( 100vw - 237px ) / 2 + var(--main-right-next-mob))

@media screen and (min-width: 1216px) and (max-width: 1900px) 
    .teamMain
        padding-top: 121px
        padding-bottom: 33px
        display: flex
        justify-content: center

        background: var(--milkyBG) 
        position: relative
        overflow: hidden
    .team
        position: relative
        max-width: 1216px
        z-index: 10
        padding: 0
        &__title
            margin: 0 0 42px 0px
            font-size: 64px
            line-height: 76.8px
            text-transform: uppercase
        .wrapper
            position: relative
            height: 544px
            display: flex
            .team__carousel
                position: relative
                align-self: flex-end
                transition: transform 0.3s ease-in-out
                transform: translateX(var(--main-right-next-lap))
                display: flex
                flex-wrap: nowrap
                gap: 40px
                max-height: 544px
                &--images
                    position: relative
                    transform: none
                    align-self: flex-end
                    pointer-events: none
                    height: 319px
                    width: 248px
                .current
                    animation: currentAnim 300ms ease-in-out
                    height: 544px
                    width: 430px
                .prev 
                    animation: previous 300ms ease-in-out
            .nextAnim
                // animation: nextAnim 300ms ease-in-out
            .prevAnim
                // animation: prevAnim 300ms ease-in-out 
        &__arrows
            position: absolute
            display: flex
            bottom: 386px 
            z-index: 20
            justify-content: space-between
            width: 1216px
            height: 57px
            &--left
                position: relative
                left: 0
                padding: 0
                width: 57px
                cursor: pointer
                bottom: 0
                transform: rotate(180deg)
            &--left:hover 
                opacity: 0.6
            &--right
                position: relative
                padding: 0 
                width: 57px
                cursor: pointer
                bottom: 0
            &--right:hover
                opacity: 0.6
        &__info
            text-transform: uppercase
            position: absolute
            z-index: 10
            width: 1216px
            box-sizing: border-box
            display: flex
            padding-left: 653px
            flex-direction: column
            top: 0
            gap: 16px
            &--name 
                padding: 0
                font-family: Jost
                font-size: 36px
                font-weight: 400
                line-height: 43.2px
                text-align: left
                bottom: 0px
                position: relative
            &--pos 
                bottom: 0px
                position: relative
                padding: 0
                font-family: Jost
                font-size: 14px
                font-weight: 500
                line-height: 16.8px
                text-align: left
        .update
            animation: txtUpdate 300ms ease-in-out
        .newUpdate
            animation: txtNewUpdate 300ms ease-in-out
    @keyframes nextAnim 
        0%
            right: var(--main-right-prev-lap)
        
        100%
            right: var(--main-right-next-lap)

    @keyframes prevAnim 
        0%
            right: var(--main-left-prev-lap)
        
        100%
            right: var(--main-right-next-lap)   

    @keyframes currentAnim 
        0%
            height: 319px
            width: 248px
        100%
            height: 544px
            width: 430px
    @keyframes previous 
        0% 
            height: 544px
            width: 430px
        100%
            height: 319px
            width: 248px

    @keyframes txtUpdate 
        0%
            opacity: 1
        100%
            opacity: 0

    @keyframes txtNewUpdate
        0% 
            opacity: 0
        100% 
            opacity: 1

</style>

<script>
import axios from 'axios';
export default {
    data() {
      return {
        currentIndex: 2,
        startX: 0,
        endX: 0,
        nxt: false,
        prev: false,
        offset: 356,
        offsetMob: 0,
        offsetLap: 288,
        update: false,
        move: 0,
        btnReady: true,
        protocol: this.$store.state.protocol,
        host: this.$store.state.host,
        images1: [],
        teamData: false
      };
    },
    methods: {
        nextMember(){
            if(this.btnReady===true){
                this.update = true
                this.btnReady = false
                setTimeout(()=>{
                    this.currentIndex+=1
                    this.offsetMob += 117
                    this.offsetLap += 288
                    if(this.currentIndex===(this.images1.length-4)){
                        this.currentIndex=2
                        this.offset=0
                        this.offsetMob = 0
                        this.offsetLap = 288
                        // const images = document.querySelectorAll('.image');
                        // images.forEach((image) => {
                        //     image.style.transition = 'none'; // Отключаем трансформацию
                        // });
                    }
                    this.offset += 356
                    this.nxt = true
                    this.update=false
                    this.move=1
                    
                },0)
                setTimeout(()=>{
                    this.nxt=false
                    this.btnReady=true
                    this.move=0
                }, 300)
                
            } 
        },
        prevMember(){
            
            if(this.btnReady === true){                
                this.update = true 
                this.btnReady = false
                setTimeout(()=>{
                    this.currentIndex -=1
                    this.offsetMob -= 117
                    this.offsetLap -= 288
                    if(this.currentIndex===1){
                        this.currentIndex=this.images1.length-5
                        this.offset = 356 * (this.images1.length-5)
                        this.offsetMob = 117 * (this.images1.length-5)-234
                        this.offsetLap = 288 * (this.images1.length-5)-288
                        
                    }
                    this.offset -= 356
                    
                    this.prev = true
                    this.update=false
                    this.move=2
                    
                },0)

                setTimeout(()=>{
                    this.prev=false
                    this.btnReady = true
                    this.move=0
                }, 300)
            } 
        },
        onTouchStart(e) {
            this.startX = e.touches[0].clientX;
        },
        onTouchMove(e) {
            this.endX = e.touches[0].clientX;
        },
        onTouchEnd() {
            const diff = this.startX - this.endX;
            if (Math.abs(diff) > 50 && this.endX > 0) {
                if (diff > 0) {
                this.nextMember();
                } else {
                this.prevMember();
                }
            }
            this.startX = 0
            this.endX = 0
            
        },
        
    },
    mounted(){
        axios.get(`${this.protocol}://${this.host}/api/participants`)
        .then(response => {
            this.images1 = response.data.data
            this.images1.unshift(this.images1[this.images1.length-1])
            this.images1.unshift(this.images1[this.images1.length-2])
            this.images1.push(this.images1[2])
            this.images1.push(this.images1[3])
            this.images1.push(this.images1[4])
            this.images1.push(this.images1[5])
            this.teamData=true
            this.$emit('teamData', this.teamData)
        })
    }
}
</script>
