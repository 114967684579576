<template>
    <div class="shellCases">
        <div class="cases">
            <div class="upper">
                <div class="titleh1">
                    {{ title }}
                </div>
                <div class="arrows">
                    <div class="left">
                        <img src="../assets/icons/arrow.svg" alt="" class="leftArrow">
                        <img src="../assets/icons/arrowHover.svg" alt="" class="leftHover" @click="prevBlock">
                    </div>
                    <div class="right">
                        <img src="../assets/icons/arrow.svg" alt="" class="rightArrow">
                        <img src="../assets/icons/arrowHover.svg" alt="" class="rightHover" @click="nextBlock">
                    </div>

                </div>
            </div>
            <div class="content" @wheel="onWheel"
            :style="{'--main-right-next': `${-offset}px`, '--main-right-next-mob': `${-offsetMob}px`, '--main--right-next-lap': `${-offsetLap}px`}"
            @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">
                <div class="images" v-for="(item,index) in cases1" 
                :key="index" 

                :class="{prev: index<currentBlock, show: index>=currentBlock, light: item.theme==='light', dark: item.theme==='dark',current: currentCase.casesId==item.id}">
                    <img :src="item.src" alt="" class="background" v-if="currentCase.casesId!=item.id">
                    <div class="subtitle bf" v-if="currentCase.casesId!=item.id">
                        {{ item.txt }}
                    </div>
                    <router-link :to="`/cases/${item.id}`" class="hiddenText" @click="selectCase(item.id)" v-if="currentCase.casesId!=item.id">
                        <div class="cardText sbf" >Смотреть проект <img src="../assets/icons/arrowItalic.svg" alt="" v-if="item.theme === 'light'"> <img src="../assets/icons/arrowItalicWhite.svg" alt="" v-if="item.theme === 'dark'"></div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="sass" scoped>
@media (min-width: 1901px)   
    .cases 
        position: relative
        padding: 132px 0 154px 144px
        background: var(--milkyBG)
        overflow: hidden
        .upper
            display: flex
            justify-content: space-between
            align-items: center
            text-transform: uppercase
            margin-bottom: 49px
            .arrows
                margin-right: 104px
                display: flex
                gap: 48px
                .left   
                    position: relative
                    width: 87px
                    height: 87px
                    cursor: pointer
                    .leftArrow
                        width: 87px
                        height: 87px
                        z-index: 10
                        position: absolute
                        transform: rotate(180deg)
                    .leftHover
                        animation: hideLink 300ms ease-in-out
                        z-index: 10
                        position: absolute
                        top: 0
                        width: 87px
                        height: 87px
                        transform: rotate(180deg)
                        opacity: 0
                    .leftHover:hover
                        animation: showLink 300ms ease-in-out
                        opacity: 1
                .right  
                    position: relative 
                    width: 87px
                    height: 87px
                    cursor: pointer
                    .rightArrow
                        z-index: 10
                        width: 87px
                        height: 87px
                        position: absolute
                    .rightHover
                        animation: hideLink 300ms ease-in-out   
                        z-index: 10
                        width: 87px
                        height: 87px
                        position: absolute
                        top: 0
                        opacity: 0
                    .rightHover:hover
                        animation: showLink 300ms ease-in-out
                        opacity: 1
        
                   
        .content
            position: relative
            z-index: 3
            display: flex
            transition: transform 0.3s ease-in-out
            gap: 48px
            transform: translateX(var(--main-right-next))
            flex-wrap: nowrap
            width: 10000px
            .prev
                animation: hideLink 300ms ease-in-out
                opacity: 0
            .show
                animation: showLink 300ms ease-in-out
            .images
                position: relative
                height: 598px
                width: 598px
                display: flex
                justify-content: center
                align-items: center
                padding: 40px 63px 80px 63px
                border-radius: 10px
                border: 1px solid var(--txtDark)
                box-sizing: border-box
                .background 
                    position: relative
                    width: 100%
                    height: 100%
                    width: 470px
                    height: auto
                .subtitle
                    position: absolute
                    display: flex
                    border-radius: 10px
                    top: -16px
                    right: -16px
                    padding: 10px 20px
                    text-transform: uppercase
                .hiddenText
                    animation: hideLink 300ms ease-in-out
                    z-index: 10
                    position: absolute
                    top: 0
                    left: 0
                    width: 100%
                    height: 100%
                    opacity: 0
                    cursor: pointer
                    .cardText
                        position: absolute
                        bottom: 22px
                        right: 40px
                        align-items: center
                        display: flex
                        gap: 8px
                        text-transform: uppercase                    
                .hiddenText:hover
                    animation: showLink 300ms ease-in-out
                    opacity: 1
            
            .images:nth-child(odd) .subtitle
                background: var(--accentPink) 
            .images:nth-child(even) .subtitle
                background: var(--accentBlue)
                color: var(--milkyBG)
            .current
                display: none
            .light
                background: var(--milkyBG)
            .light .hiddenText .cardText 
                color: var(--txtDark)
            .dark
                background: var(--txtDark)
            .dark .hiddenText .cardText 
                color: var(--milkyBG)
    .casesPage
        .upper
            .titleh1
                font-size: 64px
                font-style: normal
                font-weight: 400
                line-height: 120%
                text-transform: uppercase

    @keyframes showLink 
        0%
            opacity: 0
        
        100%
            opacity: 1
        
    @keyframes hideLink 
        0%
            opacity: 1
        
        100%
            opacity: 0
    @keyframes transfRight
        0%
            right: var(--main-right-prev)
        100%
            right: var(--main-right-next)
    @keyframes transfLeft
        0%
            right: var(--main-left-prev)
        100%
            right: var(--main-right-next)

@media (max-width: 1215px) 
    .cases
        position: relative
        padding: 56px 0 56px 16px
        overflow: hidden
        background: var(--milkyBG)
        .upper 
            display: flex
            position: relative
            justify-content: space-between
            align-items: center
            text-transform: uppercase
            z-index: 3
            margin-bottom: 24px
            padding-right: 16px
            .arrows
                display: none
        .content
            position: relative
            z-index: 3
            display: flex
            transition: transform 0.3s ease-in-out
            transform: translateX(var(--main-right-next-mob))
            gap: 24px
            flex-wrap: nowrap
            width: auto
            .prev
                animation: hideLink 300ms ease-in-out
                opacity: 0
            .show
                animation: showLink 300ms ease-in-out
            
            .images
                position: relative
                min-width: 232px
                border-radius: 10px 
                display: flex
                align-items: center
                padding: 14px 24px 34px 24px
                box-sizing: border-box
                border: 1px solid var(--txtDark)
                .background
                    width: 100%
                    
                .subtitle
                    position: absolute
                    display: flex
                    border-radius: 10px
                    top: -16px
                    right: -16px
                    padding: 5px 13px
                    font-size: 24px
                    text-transform: uppercase
                .hiddenText
                    z-index: 10
                    position: absolute
                    top: 0
                    left: 0
                    width: 100%
                    height: 100%
                    .cardText
                        position: absolute
                        bottom: 13px
                        right: 16px
                        align-items: center
                        display: flex
                        gap: 4px
                        text-transform: uppercase
                        font-size: 14px
                        line-height: 120%
                        font-weight: 500
                        img
                            width: 18px
                            height: 18px
                    .hiddenDark
                        color: var(--txtDark)
                    .hiddenLight
                        color: var(--milkyBG)
            .current
                display: none
            .light
                background: var(--milkyBG)
            .light .hiddenText .cardText 
                color: var(--txtDark)
            .dark
                background: var(--txtDark)
            .dark .hiddenText .cardText 
                color: var(--milkyBG)
            .images:nth-child(odd) .subtitle
                background: var(--accentPink) 
            .images:nth-child(even) .subtitle
                background: var(--accentBlue)
                color: var(--milkyBG)
            
        .noise
            position: absolute
            z-index: 1
            top: 0
            left: 0
            width: 100%
            height: 100%
            background: url('/src/assets/noise.png')
            mix-blend-mode: overlay
    .casesBlock
        position: relative
        .upper
            position: relative
            .titleh1
                font-size: 24px
                font-style: normal
                font-weight: 400
                line-height: 120%
                text-transform: uppercase
    @keyframes showLink 
        0%
            opacity: 0
        
        100%
            opacity: 1
        
    @keyframes hideLink 
        0%
            opacity: 1
        
        100%
            opacity: 0

    @keyframes transfRight
        0%
            right: var(--main-right-prev-mob)
        100%
            right: var(--main-right-next-mob)
    @keyframes transfLeft
        0%
            right: var(--main-left-prev-mob)
        100%
            right: var(--main-right-next-mob)

@media screen and (min-width: 1216px) and (max-width: 1900px)
    .shellCases
        display: flex
        width: 100% 
        justify-content: center
        position: relative
        padding: 100px 0 87px 0
        background: var(--milkyBG)
        
        .cases 
            position: relative
            width: 1216px
            overflow: visible
            .upper
                display: flex
                justify-content: space-between
                align-items: center
                text-transform: uppercase
                margin-bottom: 54px
                .titleh1
                    font-size: 64px
                    line-height: 76.8px
                .arrows
                    display: flex
                    gap: 40px
                    .left   
                        position: relative
                        width: 46px
                        height: 46px
                        cursor: pointer
                        .leftArrow
                            width: 46px
                            height: 46px
                            z-index: 10
                            position: absolute
                            transform: rotate(180deg)
                        .leftHover
                            animation: hideLink 300ms ease-in-out
                            z-index: 10
                            position: absolute
                            top: 0
                            width: 46px
                            height: 46px
                            transform: rotate(180deg)
                            opacity: 0
                        .leftHover:hover
                            animation: showLink 300ms ease-in-out
                            opacity: 1
                    .right  
                        position: relative 
                        width: 46px
                        height: 46px
                        cursor: pointer
                        .rightArrow
                            z-index: 10
                            width: 46px
                            height: 46px
                            position: absolute
                        .rightHover
                            animation: hideLink 300ms ease-in-out   
                            z-index: 10
                            width: 46px
                            height: 46px
                            position: absolute
                            top: 0
                            opacity: 0
                        .rightHover:hover
                            animation: showLink 300ms ease-in-out
                            opacity: 1
            
                    
            .content
                position: relative
                z-index: 3
                display: flex
                transition: transform 0.3s ease-in-out
                gap: 48px
                transform: translateX(var(--main--right-next-lap))
                flex-wrap: nowrap
                width: auto
                .prev
                    animation: hideLink 300ms ease-in-out
                    opacity: 0
                .show
                    animation: showLink 300ms ease-in-out
                .images
                    position: relative
                    height: 420px
                    width: 420px
                    display: flex
                    justify-content: center
                    align-items: center
                    padding: 31px 45px 58px 45px
                    border-radius: 10px
                    border: 1px solid var(--txtDark)
                    box-sizing: border-box
                    .background 
                        position: relative
                        width: 100%
                        height: 100%
                        width: 332px
                        height: auto
                    .subtitle
                        position: absolute
                        display: flex
                        border-radius: 10px
                        top: -16px
                        right: -16px
                        padding: 10px 20px
                        text-transform: uppercase
                        font-size: 24px
                        line-height: 28.8px
                    .hiddenText
                        animation: hideLink 300ms ease-in-out
                        z-index: 10
                        position: absolute
                        top: 0
                        left: 0
                        width: 100%
                        height: 100%
                        opacity: 0
                        cursor: pointer
                        .cardText
                            position: absolute
                            bottom: 17px
                            right: 32px
                            align-items: center
                            display: flex
                            gap: 5.5px
                            text-transform: uppercase
                            font-size: 14px
                            font-weight: 500
                            line-height: 16.8px
                            img 
                                width: 19px             
                    .hiddenText:hover
                        animation: showLink 300ms ease-in-out
                        opacity: 1
                
                .images:nth-child(odd) .subtitle
                    background: var(--accentPink) 
                    
                .images:nth-child(even) .subtitle
                    background: var(--accentBlue)
                    color: var(--milkyBG)
                .current
                    display: none
                .light
                    background: var(--milkyBG)
                .light .hiddenText .cardText 
                    color: var(--txtDark)
                .dark
                    background: var(--txtDark)
                .dark .hiddenText .cardText 
                    color: var(--milkyBG)
    .casesBlock
        padding: 56px 0 178px 0
        .upper
            .titleh1
                font-size: 36px!important
                font-style: normal
                font-weight: 400!important
                line-height: 43.2px!important
                text-transform: uppercase

        @keyframes showLink 
            0%
                opacity: 0
            
            100%
                opacity: 1
            
        @keyframes hideLink 
            0%
                opacity: 1
            
            100%
                opacity: 0
        @keyframes transfRight
            0%
                right: var(--main-right-prev)
            100%
                right: var(--main-right-next)
        @keyframes transfLeft
            0%
                right: var(--main-left-prev)
            100%
                right: var(--main-right-next)
    

</style>

<script>
import axios from 'axios';
export default {
    data(){
        return {
            cases: [
                {src: './caseImages/Dizzy.png', txt: 'SMM'},
                {src: './caseImages/Korobok(1).png', txt: 'SMM'},
                {src: './caseImages/BurgerLub.png', txt: 'SMM'},
                {src: './caseImages/Luna.png', txt: 'SMM'},
                {src: './caseImages/Shake.png', txt: 'SMM'},
                {src: './caseImages/Pelmeni.png', txt: 'SMM'},
                {src: './caseImages/kSebeNaTi.png', txt: 'ПОДКАСТ'},
            ],
            cases1: [],
            currentBlock: 0,
            offset: 0,
            offsetMob: 0,
            offsetLap: 0,
            btnReady: true,
            startX: 0,
            endX: 0,
            protocol: this.$store.state.protocol,
            host: this.$store.state.host,
            casesData: false,
            casesLength: null
        }
    },
    methods: {
        nextBlock(){
            if(this.currentBlock < this.casesLength-1 && this.btnReady === true){
                this.currentBlock+=1
                if(+this.currentCase.casesId==this.cases1[this.currentBlock].id){
                    this.currentBlock+=1
                }
                this.offset += 646
                this.offsetMob += 256
                this.offsetLap += 468
                this.btnReady=false
                setTimeout(()=>{
                    this.btnReady=true
                }, 300)
                
            } 
        },
        prevBlock(){
            if(this.currentBlock > 0 && this.btnReady === true){
                this.currentBlock-=1
                if(+this.currentCase.casesId==this.cases1[this.currentBlock].id){
                    this.currentBlock-=1
                }
                this.offset -= 646
                this.offsetMob -= 256
                this.offsetLap -= 468
                this.btnReady=false
                setTimeout(()=>{
                    this.pr=false
                    this.btnReady=true
                }, 300)
            } 
        },
        onTouchStart(e) {
            this.startX = e.touches[0].clientX;
        },
        onTouchMove(e) {
            this.endX = e.touches[0].clientX;
        },
        onTouchEnd() {
            const diff = this.startX - this.endX;
            if (Math.abs(diff) > 50 && this.endX!=0 && this.btnReady === true) {
                if (diff > 0) {
                this.nextBlock();
                } else {
                this.prevBlock();
                }
            }
            this.startX = 0
            this.endX = 0
        },
        onWheel(event) {
                if(event.deltaY>0 && this.offset<(646*(this.cases1.length-1))){
                    event.preventDefault()
                    this.nextBlock()
                }else if(event.deltaY<0 && this.offset>0){
                    event.preventDefault()
                    this.prevBlock()
                }
        },
    },
    mounted(){
        axios.post(`${this.protocol}://${this.host}/api/cases/search`,{
            includes: [
                {
                    relation: "links"
                },
                {
                    relation: "effectivenesses"
                }
            ],
            filters: [
                {
                    field: "is_archived",
                    operator: "=",
                    value: false
                }
            ]
        })
        .then(response => {
            for(let elem of response.data.data){
                let obj = {}
                obj.id = elem.id
                obj.txt = elem.billet
                obj.src = elem.image
                obj.theme = elem.theme
                this.cases1.push(obj)
                
            }
            this.casesData=true
            this.$emit('casesData', this.casesData)
            this.casesLength = this.cases1.length
            if (this.currentCase.casesId!='null'){
                this.casesLength-=1
            }
            if(+this.currentCase.casesId==this.cases1[this.currentBlock].id){
                this.currentBlock+=1
            }
        })
    },
    props: {
        title: {
            type: String
        },
        currentCase: {
            type: Object
        }
    }
}
</script>