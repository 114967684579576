import { render, staticRenderFns } from "./casesPage.vue?vue&type=template&id=49a8cbd5&scoped=true"
import script from "./casesPage.vue?vue&type=script&setup=true&lang=js"
export * from "./casesPage.vue?vue&type=script&setup=true&lang=js"
import style0 from "./casesPage.vue?vue&type=style&index=0&id=49a8cbd5&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49a8cbd5",
  null
  
)

export default component.exports