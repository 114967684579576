<template>
  <div class="shell">
    <div class="main">
      <div class="menu">
        <img src="../assets/icons/menu.svg" alt="openMenu" class="menuOpen" @click="menuOpen">
      </div>
      <div class="noise"></div>
      <div class="titleh1">
        масштабируем воздействие <br>проектов на&nbsp;мир
      </div>
      <div class="companyName desc bf">
        <div>КРЕАТИВНОЕ АГЕНТСТВО WiDEN</div>
      </div>
      <div class="companyName mob bf">
        <div>КРЕАТИВНОЕ АГЕНТСТВО</div>
      </div>
      <router-link :to="{path: '/', hash: 'application'}" class="btn b2f">
        <div class="txt">
          Запись на аудит
        </div>
      </router-link>
    </div>
  </div>
</template>

<style lang="sass" scoped>


@media (min-width: 1901px)
  .main
    position: relative
    z-index: 0
    background: var(--txtDark) url('../assets/Anim.gif')
    background-size: cover
    background-position: 100%
    width: 100%
    box-sizing: border-box
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 8px 18px 48px 9px
    .menu
      display: none
    .titleh1
      font-size: 144px
      font-weight: 400
      line-height: 156px
      margin: 128px 359px 205px 63px
      text-transform: uppercase
      color: var(--milkyBG)
      position: relative
      font-weight: 400
      z-index: 2
    .companyName
      position: relative
      z-index: 2
      color: var(--accentPink)
      margin-right: 0
      display: flex
      justify-content: center
      text-align: center
    .mob  
      display: none
    .btn
      display: none

@media (max-width: 1215px)
  .main 
    position: relative
    width: 100%
    box-sizing: border-box
    padding: 96px 8px 42px 8px
    display: flex
    flex-direction: column
    text-align: center
    background: var(--txtDark) url(../assets/AnimPhone.gif)
    background-size: 500%
    background-position: 50% 55%
    .titleh1
      color: var(--milkyBG)
      position: inherit
      z-index: 2
      margin-bottom: 133px
    .companyName
      position: inherit
      z-index: 2
      color: var(--accentPink)
      margin-bottom: 162px
    .desc
      display: none
    .btn
      z-index: 2
      text-decoration: none
      color: var(--txtDark)
      background: var(--milkyBG)
      padding: 10px 75px
      border-radius: 10px
      display: flex
      align-items: center
      justify-content: center
    .menu
      z-index: 100
      position: absolute
      top: 0
      display: flex
      justify-content: flex-end
      left: 0
      width: 100% 
      .menuOpen
        position: relative
        width: 40px
        margin: 28px 16px 0 0
        right: 0

@media screen and (min-width: 1216px) and (max-width: 1900px) 
  .shell
    display: flex
    justify-content: center
    
    background: var(--txtDark) url('../assets/Anim.gif')
    background-size: cover
    background-position: 100%
    .main
      position: relative
      max-width: 1216px
      z-index: 0
      width: 100%
      box-sizing: border-box
      display: flex
      flex-direction: column
      justify-content: space-between
      .menu
        display: none
      .titleh1
        margin: 120px 0 262px 0
        text-transform: uppercase
        color: var(--milkyBG)
        position: relative
        font-weight: 400
        font-size: 90px
        line-height: 109px
        z-index: 2
      .companyName
        position: relative
        font-size: 24px
        bottom: 62px
        z-index: 2
        color: var(--accentPink)
        margin-right: 0
        display: flex
        justify-content: center
        text-align: center
      .mob  
        display: none
      .btn
        display: none    
  


</style>

<script>
export default {
  data(){
    return{
      menuReady: true
    }
  },
  methods: {
    menuOpen(){
      if (this.menuReady===true){
        this.$store.commit('headerMobile')
      }
    }
  }
}
</script>